/**
 * 模块名称: 特殊转款申请页
 * 
 */

import React, { useState, useEffect, useRef } from 'react'
import {
  Table,
  Form,
  Alert,
  Button,
  Input,
  Select,
  message,
  Icon,
  Spin,
  Checkbox,
  Radio,
  Upload,
  Steps,
  Descriptions,
  Tag,
  Tooltip
} from 'antd'
import api from '@/api'
import { parseSearch } from '@/utils'
import { upLoadModule } from '@/utils/common'

const dItem = Descriptions.item
const { Step } = Steps;
const { Column } = Table
const Option = Select.Option
const { Dragger } = Upload;
let pageSize = 10
let currentPage = 1
let pageSize2 = 10
let currentPage2 = 1
let selectedIds = []
let xuanzhongedu = 0
// let xuanzhongFlag = 0
let toRelationType = null
let _toRelationId = 0

const SpecialAdd = (props) => {
  const { match, location, history } = props
  const { getFieldDecorator, validateFields, setFieldsValue, getFieldValue } = props.form
  const search = parseSearch(location.search)
  const [transmitData, setTransmitData] = useState({})
  const [customerInfo, setCustomerInfo] = useState({})
  const [count1, setCount1] = useState(0)
  const [count2, setCount2] = useState(0)
  const [statusList, setStatusList] = useState([])
  const [invoiceList, setInvoiceList] = useState([])
  const [transmitToStaff, setTransmitToStaff] = useState([])  // 转入人
  const [fromStaffArr, setFromStaffArr] = useState([]) // 转出人
  const [accountDetailList, setAccountDetailList] = useState([])
  const [claimList, setClaimList] = useState([])
  const [fundAccountList, setFundAccountList] = useState([])  // 转出主体
  const [tranmitToAccountList, setTranmitToAccountList] = useState([])  // 转入主体
  const [detailDataId, setdetailDataId] = useState(0)
  const [accountId, setAccountId] = useState('')
  // const [toAccountId, setToAccountId] = useState('')
  const [drawerTitle, setDrawerTitle] = useState('特殊转款申请')
  const [selectedCount, setSelectedCount] = useState(0)
  const [saveLoading, setSaveLoading] = useState(false)
  const [processShow, setProcessShow] = useState(false)
  const [fileList, setFileList] = useState([])
  const [toRelationNameInfo, setToRelationNameInfo] = useState('')  // 转入ID 客户
  const [toRelationNameInfoOut, setToRelationNameInfoOut] = useState('') // 转出 ID客户
  const [inputDisable, setInputDisable] = useState(false)
  const [inputDisableOut, setInputDisableOut] = useState(false)
  const draggerRef = useRef(null)
  const [selectedFromAccount, setSelectedFromAccount] = useState(0)
  const [selectedToAccount, setSelectedToAccount] = useState(0)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [showLoadingIconForToStaffId, setShowLoadingIconForToStaffId] = useState("") // 转入人loading
  const [showLoadingIconFromStaffId, setShowLoadingIconFromStaffId] = useState("") // 转出人loading
  const [toLoadingAccount, setToLoadingAccount] = useState(false) // 转入主体loading
  const [fromLoadingAccount, setFromLoadingAccount] = useState(false) // 转出主体loading

  const [stateVal, setStateVal] = useState(undefined)
  const [isDisabled, setIsDisabled] = useState('')
  const [companyIdOut, setCompanyIdOut] = useState('')
  const [fromRelationType, setFromRelationType] = useState(null)  // 转出类型
  const [invoiceMin, setinvoiceMin] = useState(null)
  const [checkVal, setCheckVal] = useState(false)
  const [pageLoading, setPageLoading] = useState(false)

  useEffect(() => {
    if (search.id > 0) {
      setPageLoading(true)
      //驳回重提
      setDrawerTitle('特殊转款驳回重提')
      setProcessShow(true)
      //获取转款详情
      api.fundMoneyTransmitDetail({ id: search.id }).then(data => {
        setTransmitData(data)
        setdetailDataId(data.id)
        setSelectedFromAccount(data.fromAccountId)
        setSelectedToAccount(data.toAccountId)

        //获取客户基础信息
        api.getCustomerGetInfo({ id: data.fromRelationId, type: data.fromRelationType }, true).then(data => {
          setCustomerInfo(data)
        })
        //审批进度
        setStatusList(data.procedureStatusList ? data.procedureStatusList : [])
        //获取未收款列表
        api.getTransmitInvoiceList({ clientType: data.fromRelationType, clientId: data.fromRelationId, page: currentPage, limit: pageSize }).then(datatmp => {
          setInvoiceList(datatmp.list)
          setCount1(datatmp.count)
        })
        //获取收款信息
        api.getTransmitAccountDetailList({ id: search.id, relationType: data.fromRelationType, stfId: data.fromStaffId, relationId: data.fromRelationId, accountId: data.fromAccountId, editFlag: 1, invoiceMin: invoiceMin, page: currentPage2, limit: pageSize2 }).then(datatmp => {
          setAccountDetailList(datatmp.list)
          setTimeout(() => {
            setClaimList(datatmp.allClaimIdList)
            selectedIds = data.cashIDs.split(',').map(item => +item).filter(num => num > 0)
            setSelectedRowKeys(selectedIds)
          }, 300)
        })
        //获取转出方主体账户列表
        api.getFundAccountList({ relationType: data.fromRelationType, stfId: data.fromStaffId, customerId: data.fromRelationId, type: 1 }).then(data0 => {
          setFundAccountList(data0.list)
          selectZhuTi(data.fromAccountId, data0.list) //手动触发转出主体，实现反显效果
          setTimeout(() => {
            setPageLoading(false)
          }, 1000)
        }).catch(() => {
          setPageLoading(false)
        })
        setProcessShow(true)
        //设置附件信息
        let attachments = eval('(' + data.attachments + ')')
        attachments.forEach((item, index) => {
          fileList.push({
            status: 'done',
            uid: index,
            name: item.name,
            url: item.url
          })
        })
        setFileList(attachments.map((item, index) => ({ ...item, uid: index })))

        //设置转入信息区域的反显效果
        // onChooseRadio(data.toRelationType) //初始化客户类型单选框
        // onChooseRadioOut(data.fromRelationType) // 转出类型
        setFromRelationType(data.fromRelationType)
        toRelationType = data.toRelationType

        setToRelationNameInfo(data.toRelationName + "(ID:" + data.toRelationId + ")")//设置转入ID对应的转入方的名称
        setToRelationNameInfoOut(data.fromRelationName + "(ID:" + data.fromStaffId + ")")
        getSpecialToPeople(data.toRelationType, data.toRelationId) //查询转入人列表
        getSpecialFromPeople(data.fromRelationType, data.fromRelationId) //查询转出人列表

        setFieldsValue({
          'money': data.money,
          'remark': data.remark,
          'toRelationType': data.toRelationType,
          'toRelationId': data.toRelationId,
          'toStaffId': data.toStaffId,
          'fromRelationType': data.fromRelationType,
          'fromRelationId': data.fromRelationId,
          'fromStaffId': data.fromStaffId
        })

        api.getFundAccountList({ relationType: toRelationType, customerId: data.toRelationId, type: 1, stfId: data.toStaffId }).then(data2 => { //获取转入主体列表
          setTranmitToAccountList(data2.list)
          selectZhuTi2(data.toAccountId, data2.list)
        })
      })

    } else {
      //转款申请
      setDrawerTitle('特殊转款申请')
      setProcessShow(false)
    }
  }, [])

  const onChangeTable1 = (pagination, filters, sorter) => {
    currentPage = pagination.current
    pageSize = pagination.pageSize
    api.getTransmitInvoiceList({ clientType: fromRelationType, clientId: getFieldValue('fromRelationId') ? getFieldValue('fromRelationId') : '', page: currentPage, limit: pageSize }).then(datatmp => {
      setInvoiceList(datatmp.list)
    })
  }
  //监听claim的变化修改已选中发票额度
  useEffect(() => {
    changeXuanzEDu()
  }, [claimList])

  const changeXuanzEDu = () => {
    xuanzhongedu = 0
    for (var i in claimList) {
      for (var j in selectedIds) {
        if (claimList[i].id + '' == selectedIds[j] + '' && claimList[i].invoice > 0) {
          xuanzhongedu += parseFloat(claimList[i].invoice)
        }
      }
    }
    xuanzhongedu = xuanzhongedu.toFixed(2)
  }

  const onChangeTable2 = (pagination, filters, sorter) => {
    currentPage2 = pagination.current
    pageSize2 = pagination.pageSize
    api.getTransmitAccountDetailList({ 
      id: search.id,
      relationType: fromRelationType, 
      stfId: getFieldValue('fromStaffId')? getFieldValue('fromStaffId'): '', 
      relationId: getFieldValue('fromRelationId') ? getFieldValue('fromRelationId') : '', 
      accountId: accountId, 
      invoiceMin: invoiceMin, 
      editFlag: 1, 
      page: currentPage2, 
      limit: pageSize2 
    }).then(datatmp => {
      setAccountDetailList(datatmp.list)
      setClaimList(datatmp.allClaimIdList)
      setCount2(datatmp.count)
      changeXuanzEDu()
    })
  }

  const rowSelection = {
    getCheckboxProps: (record) => {
      if (!accountId) {
        return { disabled: true }
      } else {
        return { disabled: false }
      }
    },
    onChange: (selectedRowKeys, selectedRows) => {
      // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows)
      setSelectedCount(selectedRows.length)
      selectedIds = selectedRowKeys
      setSelectedRowKeys(selectedIds)
      changeXuanzEDu()
    },
    selectedRowKeys
  }

  //只显示大于零
  const OnClickXuanzhongZero = (e) => {
    // xuanzhongFlag = xuanzhongFlag == 0 ? 1 : 0
    // console.log(xuanzhongFlag)
    // let invoiceMin = xuanzhongFlag ? 0 : null
    setinvoiceMin(e.target.checked === true ? 0 : null)
    setCheckVal(e.target.checked === true ? true : false)
    api.getTransmitAccountDetailList({ 
      editFlag: 1, 
      invoiceMin: e.target.checked === true ? 0 : null, 
      relationType: fromRelationType, 
      relationId: getFieldValue('fromRelationId') ? getFieldValue('fromRelationId') : '', 
      stfId: getFieldValue('fromStaffId') ? getFieldValue('fromStaffId'): '',
      accountId: accountId,
      page: currentPage2, 
      limit: pageSize2
    }).then(datatmp => {
      setAccountDetailList(datatmp.list)
      setClaimList(datatmp.allClaimIdList)
      setCount2(datatmp.count)
      changeXuanzEDu()
    })
  }

  //提交表单
  const formSubmits = (e) => {
    e.preventDefault()
    validateFields((err, vals) => {
      selectedIds = selectedIds ? selectedIds : []
      let data = {
        attachments: [],
        cashIDs: selectedIds.join(","),
        fromAccountId: vals.fromAccountId,
        fromRelationId: vals.fromRelationId,
        fromRelationType: vals.fromRelationType,
        fromStaffId: vals.fromStaffId,
        toAccountId: vals.toAccountId,
        toRelationId: vals.toRelationId,
        toRelationType: vals.toRelationType,
        toStaffId: vals.toStaffId,
        withInvoice: 0,
        money: vals.money,
        remark: vals.remark,
      }
      // if (vals.withInvoice !== 0) {
      //   if (selectedIds.join(",") == "") {
      //     message.error("关联的收款信息不能为空")
      //     setSaveLoading(false)
      //     return false
      //   }
      // }
      if (vals.fromAccountId == "") {
        message.error("请选择转出主体")
        setSaveLoading(false)
        return false
      }
      if (vals.toAccountId == "") {
        message.error("请选择转入主体")
        setSaveLoading(false)
        return false
      }
      if (Number(stateVal) === 0) {
        message.error("转入公司未实名认证")
        return
      }
      if (vals.toRelationType == vals.fromRelationType && vals.toRelationId == vals.fromRelationId && vals.toStaffId == vals.fromStaffId && vals.fromAccountId == vals.toAccountId) {
        return message.error("相同客户、相同主体、相同负责人不能转款")
      }
      fileList.map(item => {
        data.attachments.push(item)
      })
      if (!err) {
        setSaveLoading(true)
        if (detailDataId == 0) {
          //申请
          api.specialApply(data).then((res) => {
            setSaveLoading(false)
            if (res.status === 'success') {
              message.success(res.message)
              history.push('/fund/moneyTransmit')
            } else {
              message.error(res.message)
            }

          })
            .catch(() => setSaveLoading(false))
        } else {
          //驳回重提
          //data.push({id:detailDataId})
          data = { ...{ id: detailDataId }, ...data }
          api.specialReApply(data).then((res) => {
            setSaveLoading(false)
            if (res.status === 'success') {
              message.success(res.message)
              history.push('/fund/moneyTransmit')
            } else {
              message.error(res.message)
            }

          })
            .catch(() => setSaveLoading(false))
        }
      }
    })
    return false
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 4 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 20 },
    }
  }

  const selectZhuTi = (id, list, num) => {
    // console.log('转出', id)
    if (num == 0) {
      setSelectedCount(0)
      setSelectedRowKeys([])
      selectedIds = []
      xuanzhongedu = 0
    }
    currentPage2 = 1
    pageSize2 = 10
    setAccountId(id)
    let listNew = list.filter((v, i) => v.id == id)
    listNew.length !== 0 && setCompanyIdOut(listNew[0].companyId)
    api.getTransmitAccountDetailList({ 
      id: search.id, 
      relationType: fromRelationType, 
      relationId: getFieldValue('fromRelationId') ? getFieldValue('fromRelationId') : '', 
      stfId: getFieldValue('fromStaffId') ? getFieldValue('fromStaffId'): '',
      accountId: id, editFlag: 1, 
      invoiceMin: invoiceMin, 
      page: currentPage2, 
      limit: pageSize2 
    }).then(datatmp => {
      setAccountDetailList(datatmp.list)
      setClaimList(datatmp.allClaimIdList)
      setCount2(datatmp.count)
      changeXuanzEDu()
    })

    setSelectedRowKeys([])

    let templist = fundAccountList.length == 0 ? list : fundAccountList;
    setFundAccountList(templist.map(item => {
      let showYE = false;
      if (item.id === id) {
        showYE = true;
      }
      return { ...item, showYE }
    }))
  }

  const selectZhuTi2 = (id, intoData) => {
    // console.log('转入', tranmitToAccountList, '转出', companyIdOut, 'customerInfo.name', customerInfo.name, 'toRelationNameInfos', toRelationNameInfos)
    let listNew
    if (intoData) {
      listNew = (intoData.filter(v => v.id == id))[0].companyId
    } else {
      listNew = (tranmitToAccountList.filter(v => v.id == id))[0].companyId
    }
    setFieldsValue({
      toAccountId: id
    })
  }

  const MyRadio = (props) => {
    const [showYE, setShowYE] = useState(false);
    const { data } = props
    useEffect(() => {
      setShowYE(data.showYE !== undefined ? data.showYE : false)
    }, [data])
    return <><Radio.Button className="radio-container" value={data.id} disabled={tranmitToAccountList.length > 0 && data.id == isDisabled ? true : false}>
      <img className="mainNameBodySelLogo" src={data.logo} alt="" />{data.companyName}<Icon className="check" type="check" />
    </Radio.Button>{showYE ? <span style={{ marginRight: '10px' }}>余额：{data.balance}</span> : <span>&nbsp;</span>}</>
  }

  // 文件上传
  const uploadFiles = upLoadModule({
    fileList: fileList,
    setFileList: setFileList,
    draggerRef: draggerRef,
    accept: '.rar, .zip, .docx, .xlsx, .bmp, .jpg, .png',
    allowSizeType: 5,
    limitNum: 10
  })

  //转出信息单选赋值
  const onChooseRadioOut = (val) => {
    setFromRelationType(val)
    setFromStaffArr([])
    setFundAccountList([])
    setToRelationNameInfoOut("")
    setAccountDetailList([]) // 收款信息清空
    setClaimList([])
    setCount2(0)
    setAccountId('') // 主体选中清空
    setInvoiceList([]) // 未收款发票清空
    setCount1(0)

    setSelectedCount(0)
    setSelectedRowKeys([])
    selectedIds = []
    xuanzhongedu = 0
    setinvoiceMin(null)
    setCheckVal(false)

    setFieldsValue({
      'fromRelationId': undefined,
      'fromAccountId': [],
      'fromStaffId': undefined
    })
  }

  //转入信息单选赋值
  const onChooseRadio = (val) => {
    toRelationType = val
    setFieldsValue({
      'toRelationId': undefined,
      'toAccountId': [],
      'toStaffId': undefined
    })
    setToRelationNameInfo("")
    setTranmitToAccountList([])
  }

  //转出 id输入时触发事件
  const onChangeToRelationIdOut = (e) => {
    let outOaid = e.target.value
    if (fromRelationType === '' || fromRelationType === null) {
      message.error("请先选择将要转出的客户类型")
      return
    }
    setFromStaffArr([])
    setFundAccountList([])
    setToRelationNameInfoOut("")
    setAccountDetailList([]) // 收款信息清空
    setClaimList([])
    setCount2(0)
    setAccountId('') // 主体选中清空
    setInvoiceList([]) // 未收款发票清空
    setCount1(0)

    setSelectedCount(0)
    setSelectedRowKeys([])
    selectedIds = []
    xuanzhongedu = 0
    setinvoiceMin(null)
    setCheckVal(false)

    setFieldsValue({
      'fromAccountId': [],
      'fromStaffId': ''
    })
    if (inputDisableOut || e.target.value == "") {
      return
    }

    //查询转出人列表
    getSpecialFromPeople(fromRelationType, e.target.value)

    if (e.target.value) {
      setInputDisableOut(true)
      setToRelationNameInfoOut(<Spin />)
      api.getCustomerGetInfo({ id: outOaid, type: fromRelationType }, true).then(data => {
        setCustomerInfo(data)
        setdetailDataId(0)
        setInputDisableOut(false)
        setToRelationNameInfoOut(data.name + "(ID:" + data.id + ")")
        //审批进度
        setStatusList(data.procedureStatusList ? data.procedureStatusList : [])
        //未收款票据
        api.getTransmitInvoiceList({ clientType: fromRelationType, clientId: outOaid, page: currentPage, limit: pageSize }).then(datatmp => {
          setInvoiceList(datatmp.list)
          setCount1(datatmp.count)
        })
        //关联的收款信息
        api.getTransmitAccountDetailList({ 
          relationType: fromRelationType, 
          relationId: outOaid, 
          editFlag: 1, 
          invoiceMin: invoiceMin,
          stfId: getFieldValue('fromStaffId') ? getFieldValue('fromStaffId'): '',
          page: currentPage2, 
          limit: pageSize2 
        }).then(datatmp => {
          setAccountDetailList(datatmp.list)
          setClaimList(datatmp.allClaimIdList)
          setCount2(datatmp.count)
          changeXuanzEDu()
        })
      }).catch(() => {
        setToRelationNameInfoOut("")
        setInputDisableOut(false)
      })
    }
  }

  //查询转出人列表
  const getSpecialFromPeople = (fromRelationType, fromRelationId) => {
    setShowLoadingIconFromStaffId(<Spin />)
    api.getSpecialFromPeople({ clientId: fromRelationId, clientType: fromRelationType }, true).then(data => {
      setShowLoadingIconFromStaffId('')
      setFromStaffArr(data)
    }).catch(() => {
      setFromStaffArr([])
      setShowLoadingIconFromStaffId('')
    })
  }

  //转入id输入时触发事件
  const onChangeToRelationId = (e) => {
    _toRelationId = e.target.value
    if (toRelationType === '' || toRelationType === null) {
      message.error("请先选择将要转入的客户类型")
      return
    }
    setTranmitToAccountList([])
    setToRelationNameInfo("")
    setTransmitToStaff([])
    setFieldsValue({
      //'toRelationId': '',
      'toAccountId': [],
      'toStaffId': ''
    })
    if (inputDisable || e.target.value == "") {
      return
    }
    setInputDisable(true)
    setToRelationNameInfo(<Spin />)
    //查询客户信息
    getCustomerGetInfo(e.target.value, toRelationType)
    //查询转入人列表
    getSpecialToPeople(toRelationType, e.target.value)
  }

  //查询客户信息
  const getCustomerGetInfo = (toRelationId, toRelationType) => {
    api.getCustomerGetInfo({ id: toRelationId, type: toRelationType }, true).then(data => {
      setToRelationNameInfo(data.name + "(ID:" + data.id + ")")
      setInputDisable(false)
      setStateVal(data.status)
    }).catch(() => {
      setToRelationNameInfo("")
      setInputDisable(false)
      setStateVal(undefined)
    })
  }
  //查询转入人列表
  const getSpecialToPeople = (toRelationType, toRelationId) => {
    setShowLoadingIconForToStaffId(<Spin />)
    api.getSpecialToPeople({ clientId: toRelationId, clientType: toRelationType }, true).then(data => {
      //console.log(data)
      setShowLoadingIconForToStaffId('')
      setTransmitToStaff(data)
    }).catch(() => {
      setTransmitToStaff([])
      setShowLoadingIconForToStaffId('')
    })
  }

  // 转出人失焦
  const onChangeForTransmitOut = (staffIdOut) => {
    setFieldsValue({
      fromAccountId: ''
    })
    setFundAccountList([])
    setFromLoadingAccount(true)

    setAccountDetailList([]) // 收款信息清空
    setClaimList([])
    setCount2(0)
    setAccountId('') // 主体选中清空
    setSelectedCount(0)
    setSelectedRowKeys([])
    selectedIds = []
    xuanzhongedu = 0
    setinvoiceMin(null)
    setCheckVal(false)

    //根据负责人id查询提单范围的主体
    api.getFundAccountList({ relationType: fromRelationType, customerId: getFieldValue('fromRelationId') ? getFieldValue('fromRelationId') : '', stfId: staffIdOut, type: 1 }).then(data => {
      setFromLoadingAccount(false)
      setFundAccountList(data.list)
    })
  }

  // 转入人失焦
  const onChangeForTransmitTo = (staffId) => {
    setFieldsValue({
      toAccountId: ''
    })
    setTranmitToAccountList([])
    setToLoadingAccount(true)
    //根据负责人id查询提单范围的主体
    api.getFundAccountList({ relationType: toRelationType, customerId: _toRelationId, stfId: staffId, type: 1 }).then(data => {
      // if (toRelationType == fromRelationType && _toRelationId == getFieldValue('fromRelationId') && staffId == customerInfo.creatorId) {
      //   data.list.map((v) => {
      //     if (v.id == accountId) setIsDisabled(v.id)
      //   })
      // }
      setToLoadingAccount(false)
      setTranmitToAccountList(data.list)
    })
  }

  return (
    <Spin spinning={pageLoading}>
      <h3 style={{ paddingBottom: 0, marginBottom: 0 }}>{drawerTitle}</h3>
      <div className="pro-detail">
        <Descriptions title={<><span style={{ color: 'red' }}>*</span> <span>转出信息</span></>} className="pro-detail-smailtitle" column={4}>
          <dItem label="客户类型">
            {getFieldDecorator('fromRelationType')(
              <Radio.Group>
                <Radio value={0} onClick={() => onChooseRadioOut("0")}>直销</Radio>
                <Radio value={1} onClick={() => onChooseRadioOut("1")}>代理商</Radio>
              </Radio.Group>
            )}
          </dItem>
          <dItem label="转出ID" span={2} className="dItemTwo">
            {getFieldDecorator('fromRelationId', { initialValue: "" })(
              <Input disabled={inputDisable} placeholder="" onBlur={onChangeToRelationIdOut} style={{ width: 80, marginRight: 10 }} />
            )}
            {toRelationNameInfoOut}
          </dItem>
          <dItem label="转出人" className="dItemTwo">
            <span>
              {getFieldDecorator('fromStaffId', { initialValue: [] })(
                <Select placeholder={"请选择"}
                  style={{ width: 177 }}
                  onChange={(value) => onChangeForTransmitOut(value)}
                  optionFilterProp="children"
                  showSearch
                >
                  {fromStaffArr.map(item => <Option key={item.id} value={item.id} >{item.name}</Option>)}
                </Select>
              )}
            </span>
            <span>
              {showLoadingIconFromStaffId}
            </span>
          </dItem>
        </Descriptions>
        <Spin spinning={fromLoadingAccount}>
          <Descriptions title={<><span style={{ color: 'red' }}>*</span> <span>转出主体</span></>} className="pro-detail-smailtitle mainNameBodyRadio">
            <Form.Item label="" name="layout">
              {getFieldDecorator('fromAccountId', { "initialValue": selectedFromAccount })(
                <Radio.Group onChange={(e) => selectZhuTi(e.target.value, fundAccountList, 0)}>
                  {fundAccountList && fundAccountList.map(item => <MyRadio key={item.id} data={item} />)}
                </Radio.Group>
              )}
            </Form.Item>

          </Descriptions>
        </Spin>

        <div className="line-box"></div>
        {processShow ?
          <Descriptions title="当前审批进度" className="pro-detail-smailtitle"></Descriptions>
          : null}
        {processShow ?
          <div className="promotionProcess" >
            <Steps>
              {
                statusList.map((item, index) => {
                  let status = 'wait'
                  if (item.time && item.time.length > 1) {
                    status = 'process'
                  }
                  return <Step status={status}
                    key={index}
                    value={item.time}
                    title={item.nodeName}
                    description={
                      <>
                        {item.persons && <div title={`${item.persons.map((item) => item)}`}>{`${item.persons.map((item) => item)}`}</div>}
                        <div title={item.statusName}>{item.statusName}</div>
                        <div title={item.time}>{item.time}</div>
                      </>
                    }
                  />
                })
              }
            </Steps>
          </div>
          : null}

        {processShow ?
          <div className="line-box"></div>
          : null}
        <Descriptions title={<><span style={{ marginRight: 5 }}>未收款票据</span>
          <Tooltip title={<>这里显示的是该客户所有未收款的发票和收据信息</>} placement="right">
            <Icon type="info-circle" theme="twoTone" />
          </Tooltip></>} className="pro-detail-smailtitle">
        </Descriptions>
        <Alert className="corp-count" message={`总计：${count1}条数据`} type="info" showIcon />
        <Table
          dataSource={invoiceList}
          rowKey="id"
          pagination={{
            pageSize,
            total: count1,
            current: currentPage,
            showQuickJumper: true,
            showSizeChanger: true,
            pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
          }}
          style={{ marginBottom: 10 }}
          scroll={{ x: 1400 }}
          onChange={onChangeTable1}
        >
          <Column title="序号" dataIndex="number" />
          <Column title="业务编号" dataIndex="invoiceNo" />
          <Column title="客户/代理商名称" dataIndex="customerName" />
          <Column title="客户类型" dataIndex="customerType" />
          <Column title="主体" dataIndex="companyName" />
          <Column title="金额" dataIndex="money" />
          <Column title="发票类型" dataIndex="type" />
          <Column title="票据号" dataIndex="invoiceNumber" />
          <Column title="状态" dataIndex="stcodetag" />
          <Column title="最后处理时间" dataIndex="updateTime" />
          <Column title="申请人" dataIndex="applyName" />
        </Table>
        <div className="line-box"></div>
        <Descriptions title={<span>关联的收款信息</span>} className="pro-detail-smailtitle"></Descriptions>
        <Alert className="corp-count" message={`总计：${count2}条数据`} type="info" showIcon />
        <Table
          dataSource={accountDetailList}
          rowKey="id"
          pagination={{
            pageSize: pageSize2,
            total: count2,
            current: currentPage2,
            showQuickJumper: true,
            showSizeChanger: true,
            pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
          }}
          scroll={{ x: 1700 }}
          onChange={onChangeTable2}
          rowSelection={rowSelection}
        >
          <Column title="序号" dataIndex="id" fixed="left"/>
          <Column title="OAID/QID" dataIndex="relationId" />
          <Column title="客户类型" dataIndex="relationTypeCn" />
          <Column title="客户/代理商名称" dataIndex="relationName" />
          <Column title="业务编号" dataIndex="businessNo" />
          <Column title="业务类型" dataIndex="businessTypeCn" />
          <Column title="主体" dataIndex="companyName" />
          <Column title="收入" dataIndex="income" />
          <Column title="可退转额度" dataIndex="remainMoney" />
          <Column title="发票额度支出" dataIndex="cost" />
          <Column title="发票余额" dataIndex="invoice" />
          <Column title="备注" dataIndex="remark" />
          <Column title="负责人" dataIndex="operateStaffName" />
          <Column title="最后处理时间" dataIndex="updateTime" />
        </Table>
        <Descriptions title="" className="pro-detail-smailtitle">
          <dItem label="已选中发票额度">
            <div style={{ color: 'red' }}>
              {xuanzhongedu}
            </div>
          </dItem>
        </Descriptions>
        <Descriptions title="" className="pro-detail-smailtitle">
          <dItem label="仅显示发票余额大于0的收款记录">
            <div>
              {/* {getFieldDecorator('edit_xuanzhongZero', { initialValue: '1' })(<Checkbox />)} */}
              <Checkbox onClick={OnClickXuanzhongZero} checked={checkVal} defaultValue={false} />
            </div>
          </dItem>
        </Descriptions>
        <div className="line-box"></div>
        <Descriptions title={<><span style={{ color: 'red' }}>*</span> <span>转入信息</span></>} className="pro-detail-smailtitle" column={4}>
          <dItem label="客户类型">
            {getFieldDecorator('toRelationType')(
              <Radio.Group>
                <Radio value={0} onClick={() => onChooseRadio("0")}>直销</Radio>
                <Radio value={1} onClick={() => onChooseRadio("1")}>代理商</Radio>
              </Radio.Group>
            )}
          </dItem>
          <dItem label="转入ID" span={2} className="dItemTwo">
            {getFieldDecorator('toRelationId', { initialValue: "" })(
              <Input disabled={inputDisable} placeholder="" onBlur={onChangeToRelationId} style={{ width: 80, marginRight: 10 }} />
            )}
            {toRelationNameInfo}
          </dItem>
          <dItem label="转入人" className="dItemTwo">
            <span>
              {getFieldDecorator('toStaffId', { initialValue: [] })(
                <Select placeholder={"请选择"}
                  style={{ width: 177 }}
                  onChange={(value) => onChangeForTransmitTo(value)}
                  optionFilterProp="children"
                  showSearch
                >
                  {transmitToStaff.map(item => <Option key={item.id} value={item.id} >{item.name}</Option>)}
                </Select>
              )}
            </span>
            <span>
              {showLoadingIconForToStaffId}
            </span>
          </dItem>
        </Descriptions>
        <div className="line-box"></div>
        <Spin spinning={toLoadingAccount}>
          <Descriptions title={<><span style={{ color: 'red' }}>*</span> <span>转入主体</span></>} className="pro-detail-smailtitle mainNameBodyRadio">
            {/* <Form.Item label="" name="layout">
                    {getFieldDecorator('toAccountId' ,{ initialValue: selectedToAccount })(
                      <Radio.Group onChange={(e) => selectZhuTi2(e.target.value)}>
                        {tranmitToAccountList.map(item => <MyRadio key={item.id} data={item}/>)}
                      </Radio.Group>
                    )}
                </Form.Item> */}
            <Form.Item label="" name="layout">
              {getFieldDecorator('toAccountId', { "initialValue": selectedToAccount })(
                <Radio.Group onChange={(e) => selectZhuTi2(e.target.value)}>
                  {tranmitToAccountList.map(item => <MyRadio key={item.id} data={item} />)}
                </Radio.Group>
              )}
            </Form.Item>
          </Descriptions>
        </Spin>
        <div className="line-box"></div>
        {/* 表单开始 */}
        <Form onSubmit={formSubmits} {...formItemLayout}>
          <Descriptions title="" className="pro-detail-smailtitle" column={1}>
            <dItem label={<><span style={{ color: 'red' }}>*</span> <span>转款金额</span></>}>
              <Tooltip title={<>1.转款金额不能大于主体可用余额<br />2.转出后将优先从较早的收款信息上扣减发票余额</>} placement="right">
                <Icon type="info-circle" theme="twoTone" />
              </Tooltip>
            </dItem>
            <dItem>
              <Form.Item style={{marginBottom: 0}}>
                {getFieldDecorator('money', {
                  rules: [{ required: true, message: '请输入转款金额' }]
                })(<Input placeholder="请输入" />)}
              </Form.Item>
            </dItem>
          </Descriptions>
          <Descriptions title="附件上传" className="pro-detail-smailtitle"></Descriptions>
          <Form.Item label="">
            <div className="dragger-box" ref={draggerRef}>
              <Dragger
                {...uploadFiles}
              >
                <p className="ant-upload-drag-icon">
                  <Icon type="inbox" />
                </p>
                <p className="ant-upload-text">点击或将文件拖拽到这里上传</p>
                <p className="ant-upload-hint">
                  附件支持格式：rar、zip、docx、xlsx、bmp、jpg、png 支持添加多个附件，单个附件大小不超过2M，文件数量不超过10个。
                       </p>
              </Dragger>
            </div>
          </Form.Item>
          <Form.Item>
            <Descriptions title="" className="pro-detail-smailtitle" column={1}>
              <dItem label="备注">

              </dItem>
              <dItem>
                {
                  getFieldDecorator('remark', {
                    rules: [{ required: false, message: '请输入备注' }]
                  })(<Input.TextArea placeholder="请输入备注" rows={3} cols={250} />)
                }
              </dItem>
            </Descriptions>
          </Form.Item>
          {search.id ?
            <Descriptions title="" className="pro-detail-smailtitle">
              <dItem label="当前状态">
                <Tag color="#2db7f5">{transmitData.stCodeName}</Tag>
              </dItem>
            </Descriptions>
            : null}
          {/* <BtnGroup confirmName="重新提交" loading={saveLoading} /> */}
          {/* <Form.Item> */}
          <Button type="primary" loading={saveLoading} htmlType="submit">
            {search.id > 0 ? '重新提交' : '提交'}
          </Button>
          {/* </Form.Item> */}

        </Form>
      </div>
    </Spin>
  )
}

export default Form.create()(SpecialAdd)