/**
 * 模块名称: 新老客户对应表
 * @author zhuyan@372163.com
 */

import React, { useEffect, useState, useRef } from 'react'
import api from '@/api'
import moment from 'moment'
import {
  Alert,
  Button,
  Form,
  Input,
  Table,
  Row,
  Col,
  Spin,
  Icon,
  DatePicker,
  Select
} from 'antd'
import { parseSearch } from '@/utils'
import Export from '@/components/Export'
import Auth from '@/components/AuthMiddleware'

const { Column } = Table
const FormItem = Form.Item
const { RangePicker } = DatePicker
const Option = Select.Option
let pageSize = 10
let currentPage = 1

let selectedIds = ''

// 初始值
let exporfilter = {
  xOaId: '',
  xName: '',
  oaId: '',
  oaName: '',
  type: '',
  xAddTime: '',
  oaAddTime: ''
}

const dateFormat = 'YYYY-MM-DD'

const Invoice = (props) => {
  const { match, location, history } = props
  const { getFieldDecorator, resetFields, validateFields, setFieldsValue } = props.form
  const productsRef = useRef()
  const [loading, setLoading] = useState(false)
  const [list, setList] = useState([])
  const [count, setCount] = useState(0)
  const [showExport, setShowExport] = useState(false)
  const [authList, setAuthList] = useState([])
  const [checkedIdArr, setCheckedIdArr] = useState([])
  const [checked, setChecked] = useState(0)
  const search = parseSearch(location.search)
  const [showLoading, setShowLoading] = useState(false)
  const [expand, setExpand] = useState(false)//筛选项隐藏展示
  const [type, setType] = useState([{
    id: 0,
    name: '直销'
  },{
    id: 1,
    name: '代理商'
  },{
    id: 2,
    name: '全部'
  }])

  useEffect(() => {
    currentPage = search.page ? +search.page : 1
    pageSize = search.limit ? +search.limit : 10
    initFilter()
    getFundList()
    api.getPageAuth().then(list => setAuthList(list))
  }, [])

  // 初始化
  const initFilter = () => {
    exporfilter = {
      xOaId: '',
      xName: '',
      oaId: '',
      oaName: '',
      type: '',
      xAddTime: '',
      oaAddTime: ''
    }
  }

  // 列表
  const getFundList = () => {
    setLoading(true)
    let params = Object.assign({}, exporfilter)
    for (let i in params) {
      if (params[i] === '') delete params[i]
    }
    api.getMapNewOldClientList({
      ...params,
      limit: pageSize,
      page: currentPage,
    }).then(data => {
      setList(data.list)
      if (currentPage === 1) {
        if (data.ids) {
          localStorage.setItem('client', JSON.stringify(data.ids))
        } else {
          localStorage.setItem('client', JSON.stringify([]))
        }
      }
      setCount(data.count)
      setLoading(false)
    }).catch(() => setLoading(false))
  }

  // 提交
  const formSubmit = (e) => {
    e.preventDefault()
    validateFields((err, vals) => {
      // console.log(vals)
      if (!err) {
        if (vals.type == 2) vals.type = ''
        if (vals.xAddTime) vals.xAddTime = moment(vals.xAddTime[0]).format(dateFormat) + '~' + moment(vals.xAddTime[1]).format(dateFormat)
        if (vals.oaAddTime) vals.oaAddTime = moment(vals.oaAddTime[0]).format(dateFormat) + '~' + moment(vals.oaAddTime[1]).format(dateFormat)
        currentPage = 1
        history.replace(match.path)
        exporfilter = Object.assign({}, vals)
        for (let i in exporfilter) {
          if (exporfilter[i] === undefined) {
            exporfilter[i] = ''
          }
        }
        // console.log(exporfilter)
        getFundList()
        setCheckedIdArr([])
        setChecked(0)
      }
    })
  }
  // 重置
  const onReset = (e) => {
    resetFields()
    initFilter()
    history.replace(match.path)
    currentPage = 1
    getFundList()
    setCheckedIdArr([])
    setChecked(0)
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    }
  }

  // 导出
  const onExportFile = () => {
    setShowExport(true)
  }

  // 分页
  const onChangeTable = (pagination) => {
    // console.log(pagination)
    currentPage = pagination.current
    pageSize = pagination.pageSize
    history.replace(match.path + '?page=' + currentPage + '&limit=' + pageSize)
    getFundList()
  }

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      //console.log(selectedRowKeys, selectedRows)
      // setCheckedId(selectedRowKeys.join(","))
      setChecked(selectedRowKeys.length)
      //setCheckedId(checkedIdArr.join(","))
      selectedIds = selectedRowKeys
    },
    onSelect: (record, selected, selectedRows) => {
      // console.log(record, selected, selectedRows)
      //let arr = []
      if (!selected) {
        for (let i = 0; i < checkedIdArr.length; i++) {
          if (checkedIdArr[i] == record.id) {//eslint-disable-line
            checkedIdArr.splice(i, 1)
            i--
          }
        }
      } else {
        checkedIdArr.push(record.id)
      }
      let arrNew = [...new Set(checkedIdArr)]
      setCheckedIdArr(arrNew)
      setChecked(arrNew.length)
      selectedIds = arrNew
    },
    getCheckboxProps: record => ({
      disabled: record.name === 'Disabled User',
      name: record.name,
    }),
    //全选一列（默认十条）
    onSelectAll: (selected, selectedRows, changeRows) => {
      //console.log(selected, selectedRows, changeRows)
      let arr = []
      changeRows.forEach(item => {
        arr.push(item.id)
      })
      if (selected) {
        setCheckedIdArr([...new Set([...checkedIdArr, ...arr])])
      } else {
        setCheckedIdArr(checkedIdArr.filter(item => !arr.includes(item)))
      }
      // setChecked(arr.length)
      // selectedIds = arr
    },
    selectedRowKeys: checkedIdArr
  }
  //反选
  const onReverse = () => {
    setShowLoading(true)
    setTimeout(() => {
      let IDS = JSON.parse(localStorage.getItem('client'))
      let arr = []
      for (let i = 0; i < IDS.length; i++) {
        arr[i] = IDS[i]
      }
      let filterIds = []
      arr.forEach(item => {
        if (!checkedIdArr.includes(item)) {
          filterIds.push(item)
        }
      })
      setCheckedIdArr(filterIds)
      setChecked(filterIds.length)
      selectedIds = filterIds
      setShowLoading(false)
    }, 500)
  }
  //全选
  const onAllelection = () => {
    let IDS = JSON.parse(localStorage.getItem('client'))
    let arr = []
    for (let i = 0; i < IDS.length; i++) {
      arr[i] = IDS[i]
    }
    setChecked(arr.length)
    setCheckedIdArr(arr)
    //console.log(arr)
    selectedIds = arr
  }

  //筛选项显示隐藏
  const onToggle = () => {
    setExpand(!expand)
  }

  return (
    <Spin spinning={showLoading}>
      <Form onSubmit={formSubmit} {...formItemLayout} className="ant-advanced-search-formTwo">
        {/* <h5>其他选项: </h5> */}
        <div className="search-item-wrap">
          <div className="search-item">
            <Row gutter={24}>
              <Col span={8}>
                <FormItem label="易企信XOAID/XQID" >
                  {getFieldDecorator('xOaId')(<Input placeholder="请输入易企信ID" />)}
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label="易企信客户名称">
                  {getFieldDecorator('xName')(<Input placeholder="请输入易企信客户名称" />)}
                </FormItem>
              </Col>
              <Col span={4}> 
                <FormItem label="老ID"  >
                  {getFieldDecorator('oaId')(<Input placeholder="请输入OAID" />)}
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label="老OA客户名称">
                  {getFieldDecorator('oaName')(<Input placeholder="请输入OA客户名称" />)}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={24} className={expand ? '' : 'hide'}>
              <Col span={8}>
                <FormItem label="客户类型">
                  {getFieldDecorator('type')(
                    <Select placeholder="全部">
                      {type.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                    </Select>
                  )}
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem label="易企信创建时间">
                  {getFieldDecorator('xAddTime')(
                    <RangePicker />
                  )}
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem label="老OA创建时间">
                  {getFieldDecorator('oaAddTime')(
                    <RangePicker />
                  )}
                </FormItem>
              </Col>
            </Row>
          </div>
          <span className={`expand-btn${expand ? ' show-all' : ''}`} onClick={onToggle} style={{ cursor: 'pointer', fontSize: '12px', color: '#1890ff' }}>
            <span>{expand ? '收起' : '展开'} <Icon type={expand ? 'up' : 'down'} /></span>
          </span>
        </div>
        <div className="recruit-search-btns">
          <Auth auths={authList} code="export">
            <Button type="primary" icon="upload" onClick={onExportFile} style={{ marginRight: 10 }}>导出</Button>
          </Auth>
          <Button onClick={onReset}>重置</Button>
          <Button style={{ marginLeft: 8 }} type="primary" htmlType="submit">查询</Button>
        </div>
      </Form>
      <div className="line"></div>
      <Alert style={{ marginTop: 18 }} message={
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
            <span>已选择 <span style={{ color: '#168FFF' }}>{checked}</span> 项</span>
            {checked !== 0 &&
              <span style={{ marginLeft: 10, color: '#168FFF', cursor: 'pointer' }} onClick={onReverse}>反选</span>}
            {checked === 0 && <span style={{ marginLeft: 10, color: '#ccc', cursor: 'default' }}>反选</span>}
            <span style={{ marginLeft: 10, color: '#168FFF', cursor: 'pointer' }}
              onClick={onAllelection}>全选</span>
          </div>
          <div style={{ color: 'red', fontWeight: 700 }}>本对应表汇总对应关系请勿修改，如必须修改，请与核算部确认！</div>
        </div>
      } type="info" showIcon />
      <Table
        size="small"
        dataSource={list}
        rowKey="id"
        className="invoice-table"
        loading={loading}
        pagination={{
          pageSize,
          total: count,
          current: currentPage,
          showQuickJumper: true,
          showSizeChanger: true,
          pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
        }}
        onChange={onChangeTable}
        rowSelection={rowSelection}
      >
        <Column title="序号" dataIndex='number' />
        <Column title="易企信XOAID/XQID" dataIndex="xOaId" render={(text, record) => {
          return (<div style={record.xOaId !== record.oaId ? { color: 'red', fontWeight: 700 } : null}>{text}</div>)
        }} />
        <Column title="易企信客户名称" dataIndex="xName" />
        <Column title="易企信创建时间" dataIndex="xAddTime" />
        <Column title="老ID" dataIndex="oaId" render={(text, record) => {
          return (<div style={record.xOaId !== record.oaId ? { color: 'red', fontWeight: 700 } : null}>{text}</div>)
        }} />
        <Column title="老OA客户名称" dataIndex="oaName" />
        <Column title="老OA创建时间" dataIndex="oaAddTime" />
        <Column title="客户类型" dataIndex="type" />
      </Table>
      <Export
        show={showExport}
        onCancel={() => {
          setShowExport(false)  
          productsRef.current.changeVal()
        }}
        tplUrl="getFonsExportTpl"
        fieldsUrl="getFonsTplItems"
        saveUrl="saveFonsExportTpls"
        exportUrl="exportFons"
        method="getlist"
        parame={{ ExportType: 'mapnewoldclient.getlist', id: selectedIds, ...exporfilter }}
        cRef={productsRef}
      />
    </Spin>
  )
}

export default Form.create()(Invoice)
