/**
 * 模块名称: 认款详情页
 * @author wangchanghong@372163.com
 */

import React, { useState, useEffect, useRef } from 'react'
import {
	Descriptions,
	Steps,
	Button,
	Modal,
	Input,
	Icon,
	Form,
	Radio,
	Upload,
	message,
	Tooltip, Spin, Table, Popconfirm
} from 'antd'
import api from '@/api'
import { parseSearch, debounce, onUrge } from '@/utils'
import { upLoadModule } from '@/utils/common'
import RKInfo from "./components/renkuanInfo"
import DaoKuan from "./components/daokuan"
import CryptoJS from 'crypto-js'

const { Dragger } = Upload
const DItem = Descriptions.Item
const { Step } = Steps
const { TextArea } = Input
const { Column } = Table

let id = 0;
let disabled = true
let searchByTimer = null
let pageSize = 10
let currentPage = 1

let selectDaoKuanCB = null
let _fundAccountList = []
let userInfo = null
let clientName = '', relationList = '', relateAuthor = ''
const Detail = (props) => {
	const { getFieldDecorator, validateFields, resetFields, setFieldsValue, getFieldsValue, validateFieldsAndScroll, getFieldValue } = props.form
	const { location, history } = props
	const [detail, setDetail] = useState({})
	const [clientInfo, setClientInfo] = useState({})
	const [detailDataId, setDetailDataId] = useState(0)
	const [relationShipList, setRelationShipList] = useState([])
	const [logConList, setLogConList] = useState([])
	const [statusList, setStatusList] = useState([])
	const [buttonAuthList, setButtonAuthList] = useState([])
	const [passModalVisible, setModalVisible] = useState(false)
	const [turnModalVisible, setTurnModalVisible] = useState(false)
	const [textAreaVal, setTextAreaVal] = useState('')
	const [textDisplay, setTextDisplay] = useState(true)
	const [pasDesc, setPasDesc] = useState('')
	const [displayThirdRelation, setDisplayThirdRelation] = useState(true)
	const [fundAccountList, setFundAccountList] = useState([]);
	const [payList, setPayList] = useState([])
	const [RKForms, setRKForms] = useState([])
	const [daoKuanVisible, setDaoKuanVisible] = useState(false);
	const [relationPro, setRelationPro] = useState('');
	const [showRelationPro, setShowRelationPro] = useState(false);
	const [RKTotal, setRKTotal] = useState(0)
	const [DKIds, setDKIds] = useState([])
	const [canSelectId, setCanSelectId] = useState(0)
	const [fileList, setFileList] = useState([])
	const [submitLoading, setSubmitLoading] = useState(false)
	const [relationErrClass, setRelationErrClass] = useState('')
	const [RKZhuti, setRKZhuti] = useState(0)
	const [zhutiErrClass, setZhutiErrClass] = useState('')
	const [canEdit, setCanEdit] = useState(true)
	const [detailLoading, setDetailLoading] = useState(true)
	const [reload, setReload] = useState({})
	const [payNameList, setPayNameList] = useState('')
	const [payDefaultNameList, setPayDefaultNameList] = useState('')
	const [idVal, setIdVal] = useState ('')
	const [authorizationList, setAuthorizationList] = useState([])
	const [radioId, setRadioId] = useState(null)
	const [no, setNo] = useState('')
	const [client, setClient] = useState('')
	const [authorName, setAuthorName] = useState('')
	const [authorAccount, setAuthorAccount] = useState('')
	const [relation, setRelation] = useState('')
	// const [isUpPowerOfAttorney, setIsUpPowerOfAttorney] = useState(null)
	// const [isCopyPowerOfAttorney, setIsCopyPowerOfAttorney] = useState(null)
	const [isUpPayAgreement, setIsUpPayAgreement] = useState(null)
	// const [fileListRk, setFileListRk] = useState([])
	const [isCopyPayAgreement, setIsCopyPayAgreement] = useState(null)
	const [fileListRk2, setFileListRk2] = useState([])
	const [remark, setRemark] = useState('')
	const [count, setCount] = useState(0)
	const [initialData, setInitialData] = useState({})
	const [forbidden, setForbidden] = useState(false)
	const [nameSame, setNameSame] = useState(false)
	const [addFlag, setAddFlag] = useState(true)
	const [seachLoading, setSeachLoading] = useState(false)

	const search = parseSearch(location.search)
	const draggerRef = useRef(null)
	const draggerRkRef = useRef(null)
	const draggerRkRef2 = useRef(null)
	const relationGroupRef = useRef(null)
	const zhuTiRef = useRef(null)

	useEffect(() => {
		searchByTimer = null
		relateAuthor = ''
	}, [])

	id = search.id
	useEffect(() => {
		// console.log(search.new)
		if (search.id) {
			let plaintext = search.sign
			let plaintextTwo = CryptoJS.MD5(search.id + 'ad629fddf8b8756d2e72e96ae035a5a4' + search.time).toString()
			
			if (plaintext == plaintextTwo && +new Date() - +search.time <= 21600000) {
				api.fundMoneyClaimDetail({ id: search.id }).then(data => {
					if (search.msgId) {
						api.setMessageReaded({ ids: [search.msgId] })
					}
					setInitialData(data)
					const apis = [
						api.getCustomerGetInfo({ id: data.relationId, type: data.relationType }),
						api.fundMoneyClaimGetRelationShip(),
						api.fundMoneyBackGetPayType(),
						api.getUser(),
					]
	
					setDetailLoading(true)
					Promise.all(apis)
						.then(res => {
							const _clientInfo = res.shift()
							setClientInfo(_clientInfo)
							clientName = _clientInfo.name.trim()
							setClient(clientName)
							const _relationShipList = res.shift()
							relationList = _relationShipList
							setRelationShipList(_relationShipList)
							// _fundAccountList = res.shift()
							const _payList = res.shift()
							setPayList(_payList)
							userInfo = res.shift()
	
							//这个不能写在Promise的外面，因为使用了_fundAccountList变量，而_fundAccountList变量是在这里初始化的
							if (search.id) {
								getDetail(search.id, clientName) //在getDetail里配置是否可编辑
							} else {
								api.getFundAccountList({ relationType: search.clientType, customerId: search.clientId, type: 1 }).then(res => {
									_fundAccountList = res
									setFundAccountList(_fundAccountList.list)
								})
								setCanEdit(true)
								setDetailLoading(false)
							}
						})
				}).catch(() => history.goBack())
			} else {
				message.error('没有本页访问权限')
				setTimeout(() => {
					history.go(-2)
				}, 1000)
			}
		} else {
		 // 新增进入
			api.fundMoneyClaimGetClaimType({ relationType: search.clientType }).then(data => {
				detail.claimType = data
				setDetail(detail)
			})
			const apis = [
				api.getCustomerGetInfo({ id: search.clientId, type: search.clientType }),
				api.fundMoneyClaimGetRelationShip(),
				api.fundMoneyBackGetPayType(),
				api.getUser(),
			];

			setDetailLoading(true)
			Promise.all(apis)
				.then(res => {
					const _clientInfo = res.shift()
					setClientInfo(_clientInfo)
					clientName = _clientInfo.name.trim()
					setClient(clientName)
					const _relationShipList = res.shift()
					relationList = _relationShipList
					setRelationShipList(_relationShipList)
					// _fundAccountList = res.shift()
					const _payList = res.shift()
					setPayList(_payList)
					userInfo = res.shift()

					//这个不能写在Promise的外面，因为使用了_fundAccountList变量，而_fundAccountList变量是在这里初始化的
					if (search.id) {
						getDetail(search.id, clientName) //在getDetail里配置是否可编辑
					} else {
						api.getFundAccountList({ relationType: search.clientType, customerId: search.clientId, type: 1 }).then(res => {
							_fundAccountList = res
							setFundAccountList(_fundAccountList.list)
						})
						setCanEdit(true)
						setDetailLoading(false)
					}
				})

		}
		canEdit && !search.id && addRKForm()
	}, [reload])

	//获取认款详情
	const getDetail = (id, clientVal) => {
		setDetailLoading(true)
		api.fundMoneyClaimDetail({ id }).then(
			detail => {
				setDetail(detail)
				setDetailDataId(detail.id)
				relateAuthor = detail.author
				setStatusList(detail.procedureStatusList)
				setButtonAuthList(detail.buttonAuthList || {})
				setLogConList(detail.procedureLogList)
				setFieldsValue({ accountId: detail.accountId })
				//获取转入主体账户列表
				api.getFundAccountList({ relationType: search.id ? detail.relationType : search.clientType, customerId: search.id ? detail.relationId : search.clientId, type: 1, stfId: detail.stfId, flag: 1 }).then(res => {
					_fundAccountList = res
					//setFundAccountList(_fundAccountList.list)
					_fundAccountList.list = _fundAccountList.list.map(item => mapZhuti(item, detail.accountId))
					setFundAccountList([..._fundAccountList.list])
				})

				let files = [];
				if (detail.attachments) {
					files = JSON.parse(detail.attachments)
				}
				files.forEach((item, index) => {
					fileList.push({
						status: 'done',
						uid: index,
						name: item.name,
						url: item.url
					})
				})
				setFileList(files.map((item, index) => ({ ...item, uid: index })))

				setFieldsValue({
					relationshipId: detail.relationshipId,
					remark: detail.remark
				})

				const _RKForms = detail.claimItemList.list;
				// console.log('_RKForms=>', _RKForms)
				let nList = _RKForms.reduce((total, curr) => {
					if (curr.moneyBackDetail !== null) {
						return [...total, curr.moneyBackDetail.name]
					} else {
						return [...total]
					}

				}, [])
				let res = [...new Set(nList)].filter(item => item !== clientName)
				for (let i = 0; i < res.length; i++) {
					if (res[i] == '') res = []
				}
				setPayDefaultNameList(res)
				setIdVal(detail.accountCompanyId)
				getAuthorizationList(clientVal ? clientVal : '','', detail.accountCompanyId)
				// console.log('_RKForms=>', _RKForms)
				setRKForms(_RKForms)
				setDetailLoading(false)
				setCanEdit(detail.busCode === 3 && detail.stfId === userInfo.id)

				if (_RKForms.length && _RKForms[0].moneyBackDetail) {
					let item = _RKForms[0].moneyBackDetail
					_RKForms[0].moneyBackDetail !== null && setAuthorName(item.name)
					_RKForms[0].moneyBackDetail !== null && setAuthorAccount(item.account)
				}
				relationList.forEach(item => {
					if (item.id == getFieldValue('relationshipId')) setRelation(item.name)
				})
			}
		)
	}
	//获取认款委托书列表
	const getAuthorizationList = (sn, asn, cId) => {
		setSeachLoading(true)
		api.getAuthorizationList({
			client: sn,
			authorName: asn && asn.join(','),
			companyId: cId ? cId : '',
			searchType: 'special',
			limit: pageSize,
			page: currentPage
		}).then(res => {
			setAuthorizationList([])
			setCount(res.count)
		    setSeachLoading(false)
			if (res.list.length) {
				let list = [...res.list]
				list.forEach((item, index, self) => {
					self[index].isRelated = 0
					if (item.id == relateAuthor) {
						self[index].isRelated = 1
					}
				})
				relateAuthor === 0 ? setRadioId(list[list.length - 1].id) : setRadioId(relateAuthor)
				setAuthorizationList(list)
			}
		}).catch(()=> {
		    setSeachLoading(false)
		})
	}
	//点击分页
	const onChangeTable = (pagination, filters, sorter) => {
		currentPage = pagination.current
		pageSize = pagination.pageSize
		getAuthorizationList(payNameList == '' ? client : payNameList, payDefaultNameList, idVal)
	}

	const searchByName = e => {
		let val = e.target.value
		setPayNameList(val)
		return function () {
			if (searchByTimer) clearTimeout(searchByTimer)
			searchByTimer = setTimeout(() => {
				clearTimeout(searchByTimer)
				getAuthorizationList(val == '' ? client : val, payDefaultNameList, idVal)
			}, 1500)
		}()
	}

	const getNameId = (e) => {
		// console.log(e.target.value)
		setRadioId(e.target.value)
	}

	// 通过
	const onBtnPassShow = () => {
		setModalVisible(true)
	}

	const onPassCancel = () => {
		setModalVisible(false)
	}

	const onPassDescChange = (e) => {
		setPasDesc(e.target.value)
	}

	//延期
	const onBtnDelay = () => {
		setDetailLoading(true)
		api.fundMoneyClaimAudit({
			id: detailDataId,
			type: 4
		}).then(data => {
			if (data.status == 'success') {
				message.success("延期成功");
				history.push('/fund/moneyClaim')
			} else {
				message.error(data.message);
			}
			return
			//getDetail(detailDataId) //确认通过后
		}).finally(() => {
			setDetailLoading(false)
		})
	}

	//通过
	const onApprove = () => {
		if (buttonAuthList.showAuthBook && !relateAuthor) {
			return message.info('请关联委托书')
		}
		setDetailLoading(true)
		if (disabled) {
			disabled = false
			let timer = setTimeout(() => {
				disabled = true
				clearTimeout(timer)
			}, 1000)
		} else {
			setDetailLoading(false);
			message.error('请不要频繁点击')
			return
		}
		api.fundMoneyClaimAudit({
			id: detailDataId,
			remark: pasDesc.trim(),
			type: 0
		}).then(data => {
			getDetail(detailDataId) //确认通过后
		}).finally(() => {
			setDetailLoading(false)
			setModalVisible(false)
		})
	}

	// 驳回
	const onBtnTurnShow = () => {
		setTurnModalVisible(true)
		setTextDisplay(true)
	}

	const onTurnCancel = () => {
		setTurnModalVisible(false)
	}

	//驳回
	const onTurnApprove = () => {
		if (buttonAuthList.reject_msg_require) {
			if (textAreaVal.trim() === '') {
				setTextDisplay(false)
				return
			}
		}

		setDetailLoading(true)

		if (disabled) {
			disabled = false
			let timer = setTimeout(() => {
				disabled = true
				clearTimeout(timer)
			}, 1000)
		} else {
			setDetailLoading(false);
			message.error('请不要频繁点击')
			return
		}

		api.fundMoneyClaimAudit({
			id: detailDataId,
			remark: textAreaVal.trim(),
			type: 1
		}).then(data => {
			getDetail(detailDataId) //确认驳回后
		}).finally(() => {
			setDetailLoading(false)
			setTurnModalVisible(false)
		})
	}

	const onDescChange = (e) => {
		setTextAreaVal(e.target.value)
		setTextDisplay(true)
	}

	// 驳回样式
	const turnNode = () => {
		return <>
			确认驳回 <Icon type="close-circle" theme="twoTone" twoToneColor="#DC143C" />
		</>
	}

	// 同意样式
	const agreeNode = () => {
		return <>
			确认通过 <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" />
		</>
	}

	// 转上级
	const onTurnUp = () => {
		setDetailLoading(true)
		api.fundMoneyClaimAudit({
			id: detailDataId,
			type: 2
		}).then(data => {
			getDetail(detailDataId)
		}).finally(() => {
			setDetailLoading(false)
		})
	}

	// 补充资料
	const onReplenish = () => {
		setDetailLoading(true)
		api.fundMoneyClaimAudit({
			id: detailDataId,
			type: 3
		}).then(data => {
			getDetail(detailDataId)
		}).finally(() => {
			setDetailLoading(false)
		})
	}

	const getPureName = (str) => {
		let RexStr = /\(|\)|\（|\）|\uff08|\uff09/g
		str = str.replace(RexStr, function () {
			return ''
		})
		return str
	}

	const handleSubmit = (e) => {
		e.preventDefault()

		validateFieldsAndScroll((err, vals) => {
			if (!err) {
				let nameFlag = null
				if (RKForms.length === 1) {
					let nameB = null
					if (RKForms[0].moneyBackDetail && clientInfo.name.trim()) {
						nameB = getPureName(RKForms[0].moneyBackDetail.name)
						if (getPureName(clientInfo.name.trim()) == nameB) {
							setNameSame(true)
							nameFlag = true
						}
					}
				} else if (RKForms.length > 1) {
					let nameArr = []
					for (let i = 0; i < RKForms.length; i++) {
						if (RKForms[i].moneyBackDetail && clientInfo.name.trim()) {
							let nameB = getPureName(RKForms[i].moneyBackDetail.name)

							if (getPureName(clientInfo.name.trim()) !== nameB) {
								nameArr.push(nameB)
							}
						}

					}
					if (nameArr.length) {
						setNameSame(false)
						nameFlag = false
					} else {
						setNameSame(true)
						nameFlag = true
					}
				}
				if (vals.payWay_0 == 3 || vals.payWay_0 == 4) {
						if (!nameFlag && !vals.relationshipId) {
							//ReactDOM.findDOMNode(relationGroupRef.current).scrollIntoView({behavior: 'smooth'})
							setRelationErrClass('has-err')
							message.error("请先选择打款方与客户关系")
							return
						} else {
							setRelationErrClass('')
						}
				} else {
					setRelationErrClass('')
				}

				if (!RKZhuti) {
					//ReactDOM.findDOMNode(zhuTiRef.current).scrollIntoView({behavior: 'smooth'}) 
					setZhutiErrClass('has-err')
					message.error("请选择认款主体")
					return
				} else {
					setZhutiErrClass('')
				}

				const submitVals = {
					relationType: search.id ? initialData.relationType : search.clientType,
					relationId: search.id ? initialData.relationId : search.clientId,
					accountId: RKZhuti,
					remark: vals.remark,
					claimList: [],
					attachments: []
				}

				if (vals.relationshipId) {
					submitVals.relationshipId = vals.relationshipId
				}

				for (let i in vals) {
					if (/^account\_/.test(i)) {
						let ind = i.split('_')[1]
						submitVals.claimList.push({
							account: vals['account_' + ind],
							payWay: vals['payWay_' + ind],
							remark: vals['remark_' + ind],
							rkQuota: vals['rkQuota_' + ind],
							moneyBackId: [3, 4].includes(vals['payWay_' + ind]) ? RKForms[ind].moneyBackId : 0
						})
					}
				}

				fileList.map(item => {
					submitVals.attachments.push(item)
				})
				setSubmitLoading(true)
				let _api = ''
				if (search.id) {
					_api = api.fundMoneyClaimReApply
					submitVals.id = search.id
				} else {
					_api = api.fundMoneyClaimApply
				}

				_api(submitVals).then(
					res => {
						// console.log(res)
						if (res.status === 'success') {
							message.success(res.message)
							history.push('/fund/moneyClaim')
							// history.push('/fund/moneyClaim/detail?id=' + (search.id ? search.id : res.data.claimId) + '&clientId=' + search.id ? initialData.relationId : search.clientId + '&clientType=' + search.id ? initialData.relationType : search.clientType)
							setReload({})
						} else {
							message.error(res.message)
						}

					}
				).finally(() => setSubmitLoading(false))

			} else {
				for (let i in err) {
					message.error(err[i].errors[0].message)
				}
			}


		})
	}

	const addRKForm = (data) => {
		data = data || {}
		setRKForms([...RKForms, data])
	}

	const deleteItem = (index) => {
		const delData = RKForms[index]
		RKForms.splice(index, 1)

		deleteDKIds(delData.moneyBackId)
		setRKForms([...RKForms])
	}

	const deleteDKIds = (id) => {
		setDKIds(DKIds.filter(item => {
			return id ? id !== item : true
		}))
	}

	const selectDaoKuan = (data) => {
		selectDaoKuanCB(data)
	}

	const showDaoKuan = (callback, canSelectId) => {
		setDaoKuanVisible(true)
		setCanSelectId(canSelectId)
		selectDaoKuanCB = callback
	}

	const MyRadio = (props) => {
		const { data } = props
		return <><Radio.Button className="radio-container" disabled={!canEdit} value={data.id}>
			<img className="mainNameBodySelLogo" src={data.logo} alt="" />{data.companyName}<Icon className="check" type="check" />
		</Radio.Button>{data.checked ? <span style={{ marginRight: '10px' }}>余额：{data.balance}</span> : <span>&nbsp;</span>}</>

	}

	const mapZhuti = (item, id) => {
		let checked = false;
		if (item.id === id) {
			setRelationPro(item.projectRange)
			setShowRelationPro(true)
			checked = true;
			setRKZhuti(id)
		}

		return { ...item, checked }
	}

	const selectZhuTi = (id) => {
		let newList = fundAccountList.map(item => mapZhuti(item, id));

		setFundAccountList(newList)
	}


	const setRenKuanInfo = (data) => {
		let total = 0;

		data.map(item => {
			total += item.SrJE * 100
		})
		setRKForms(data)
		setRKTotal(total / 100)
	}

	// 文件上传
	const uploadFiles = upLoadModule({
		fileList: fileList,
		setFileList: setFileList,
		draggerRef: draggerRef,
		accept: '.rar, .zip, .docx, .xlsx, .bmp, .jpg, .png',
		allowSizeType: 5,
		limitNum: 10
	})

	// 附件上传 委托书
	// const uploadFilesRk = upLoadModule({
	// 	fileList: fileListRk,
	// 	setFileList: setFileListRk,
	// 	draggerRef: draggerRkRef,
	// 	accept: '.rar, .zip, .docx, .xlsx, .bmp, .jpg, .png, .pdf',
	// 	allowSizeType: 5,
	// 	limitNum: 10
	// })

	// 附件上传 付款协议
	const uploadFilesRk2 = upLoadModule({
		fileList: fileListRk2,
		setFileList: setFileListRk2,
		draggerRef: draggerRkRef2,
		accept: '.rar, .zip, .docx, .xlsx, .bmp, .jpg, .png, .pdf',
		allowSizeType: 5,
		limitNum: 10
	})

	//关联委托书
	const relateAuthorization = () => {
		let params = {
			claimId: search.id,
			authorizationId: radioId
		}
		api.fundMoneyClaimRelateAuthorizationToClaim(params).then(() => {
			message.success('关联成功')
			let list = [...authorizationList]
			list.forEach((item, index, self) => {
				self[index].isRelated = 0
				if (item.id == radioId) {
					self[index].isRelated = 1
				}
			})
			setAuthorizationList(list)
			relateAuthor = radioId
		})
	}
	//添加委托书
	const onFortuneAuthorizationAdd = () => {
		if (!no.length) return message.warning('请输入委托书编号')
		if (!client) return message.warning('请输入委托方')
		if (!authorName) return message.warning('请输入被委托方户名')
		if (!authorAccount) return message.warning('请输入委托书账号')
		if (!relation) return message.warning('请输入关系')
		// if (isUpPowerOfAttorney == null && isUpPayAgreement == null) return message.warning('委托书或付款协议至少选择一个')
		// if (isUpPowerOfAttorney == null) return message.warning('请选择是否提交了委托书')
		// if (isCopyPowerOfAttorney == null) return message.warning('请选择委托书类型')
		// if (isUpPayAgreement == null) return message.warning('请选择是否提交了付款协议')
		// if (isCopyPayAgreement == null) return message.warning('请选择付款协议类型')
		if (isUpPayAgreement !== null && isUpPayAgreement === 1 && isCopyPayAgreement == null) {
			return message.warning('请选择指定付款协议类型')
		}
		let params = {
			companyId: detail.accountCompanyId,
			relationType: search.id ? initialData.relationType : search.clientType,
			relationId: clientInfo.id,
			relationName: clientInfo.name.trim(),
			no,
			client,
			authorName,
			authorAccount,
			relation,
			// isUpPowerOfAttorney, 委托书
			// isCopyPowerOfAttorney, 委托书类型
			// powerOfAttorneyAttachments: fileListRk,
			isUpPayAgreement,
			isCopyPayAgreement,
			payAgreementAttachments: fileListRk2,
			remark
		}
		api.onFortuneAuthorizationAdd(params).then(() => {
			message.success('添加成功')
			getAuthorizationList(payNameList == '' ? client : payNameList, payDefaultNameList, idVal)
		})
	}

	//退回列表页
	const onGoBack = () => {
		history.push({pathname:'/fund/moneyClaim',state: { goBack: 1}})
	}

	return (
		<Spin spinning={detailLoading}>
			{
				search.id && search.new && search.new == 1 && <Button onClick={onGoBack} type="primary" style={{
					position: 'absolute',
					right: '20px',
					top: 0
				}}>返回上一页</Button>
			}
			<Form onSubmit={handleSubmit}>
				<div className="pro-detail">
					<Descriptions title={search.id ? '认款详情' : '认款申请'} layout="horizontal" column={4} style={{ marginBottom: '10px' }}>
						{search.id && <DItem label="认款编号">{detail.no}</DItem>}
						<DItem label="客户类型">{clientInfo.typeName}</DItem>
						<DItem label={search.id ? initialData.relationType == 0 ? 'OAID' : 'QID' : search.clientType == 0 ? 'OAID' : 'QID'}>{clientInfo.id}</DItem>
						{detail.claimType && !Array.isArray(detail.claimType) && <DItem label="类型">{detail.claimType.name}</DItem>}
						<DItem label={(() => {
							const label = search.id ? initialData.relationType == 0 ? '客户名称' : '代理商名称' : search.clientType == 0 ? '客户名称' : '代理商名称';
							return (
								<Tooltip title={<div>
									<span>1、当客户名称/代理商名称与付款方户名不一致时，判定为第三方认款。</span>
									<br />
									<span>2、第三方客户打款需要选择打款方与客户关系。</span>
									<br />
									<span>3、选择“近亲属”必须是个人客户</span>
								</div>}>
									<span>
										<Icon className="operate-icon" style={{ color: '#aaa' }} type="info-circle" /> {label}
									</span>
								</Tooltip>
							)
						})()}>{clientInfo.name}</DItem>

						<DItem label="真实性验证号码类型">{clientInfo.verifyTypeName}</DItem>
						<DItem label="真实性验证号码">{clientInfo.verifyNumber}</DItem>
					</Descriptions>

					{!canEdit && <>
						<Descriptions 
							title={<>当前审批进度&nbsp;&nbsp;{buttonAuthList.urge && <Button type='primary' size="small" onClick={() => onUrge('urgeFundMoneyClaim', buttonAuthList.businessNo)}>催一催</Button>}</>} 
							className="pro-detail-smailtitle">
						</Descriptions>
						<div className="promotionProcess">
							<Steps>
								{
									statusList.map((item, index) => {
										let status = 'wait'
										if (item.time && item.time.length > 1) {
											status = 'process'
										}
										return <Step status={status}
											key={index}
											value={item.time}
											title={item.nodeName}
											description={
												<>
													{item.persons && <div title={`${item.persons.map((item) => item)}`}>{`${item.persons.map((item) => item)}`}</div>}
													<div title={item.statusName}>{item.statusName}</div>
													<div title={item.time}>{item.time}</div>
												</>
											}
										// {`${item.persons.map((item) => item)} ${item.statusName} ${item.time}`}
										/>
									})
								}
							</Steps>
						</div>
					</>}
					{displayThirdRelation &&
						<>
							<div className="line-box"></div>

							{
								canEdit ?
									<Form.Item ref={relationGroupRef} className={relationErrClass} label={<span>打款方与客户关系</span>} style={{ margin: '20px 0' }}>
										{getFieldDecorator('relationshipId')(
											<Radio.Group>
												{
													relationShipList.map(item => <Radio disabled={!canEdit || nameSame} key={item.id} value={item.id}>{item.name}</Radio>)
												}
											</Radio.Group>
										)}
									</Form.Item>
									:
									detail.relationshipName && <Form.Item ref={relationGroupRef} className={relationErrClass} label={<span>打款方与客户关系</span>} style={{ margin: '20px 0' }}>
										{detail.relationshipName}
									</Form.Item>

							}
						</>
					}

					<div className="line-box"></div>

					<Descriptions title="认款信息" className="pro-detail-smailtitle"></Descriptions>
					<div style={{ paddingBottom: '20px' }}>
						{
							(() => {
								return RKForms.map((data, index) => {
									return <RKInfo
										canEdit={canEdit} setDefaultSelectIDs={setDKIds}
										deleteDKIds={deleteDKIds} showDaoKuan={showDaoKuan}
										setRKForms={setRenKuanInfo} RKForms={RKForms} data={data} key={index}
										deleteItem={deleteItem} index={index} payList={payList}
										getFieldDecorator={getFieldDecorator} validateFields={validateFields}
										resetFields={resetFields} setFieldsValue={setFieldsValue}
										getFieldsValue={getFieldsValue} forbidden={forbidden}
										setNameSame={setNameSame}
										setAddFlag={setAddFlag}
										clientInfoName={clientInfo.name}
									/>
								})
							})()
						}

						{
							canEdit && addFlag &&
							<Button icon="plus" style={{ width: '330px', marginLeft: '10px', marginTop: 15 }} onClick={() => addRKForm()}>添加 </Button>
						}
					</div>

					<div className="line-box"></div>
					<Descriptions title="认款主体（请选择）" className="pro-detail-smailtitle mainNameBodyRadio">
						<Form.Item label="" ref={zhuTiRef} className={zhutiErrClass}>
							{getFieldDecorator('accountId')(
								<Radio.Group onChange={(e) => selectZhuTi(e.target.value)}>
									{fundAccountList.map(item => {
										return <MyRadio key={item.id} data={item} />
									})}
								</Radio.Group>
							)}
						</Form.Item>
					</Descriptions>
					{showRelationPro &&
						<>
							<Descriptions>
								<DItem label="项目范围">{relationPro}</DItem>
							</Descriptions>
						</>}

					<Descriptions title="" style={{ marginBottom: 0 }}>
						<DItem label="认款总额">{RKTotal}</DItem>
					</Descriptions>
					<div className="line-box"></div>
					{buttonAuthList.showAuthBook === true && <div className="authorizationBox">
						<Descriptions title="认款委托书" style={{ marginBottom: 0, marginTop: '20px' }}>
							<DItem label="委托方">
								<Input placeholder="请输入" onPressEnter={e => e.preventDefault()} style={{ width: 180, marginRight: '15px' }} onChange={searchByName} />
							</DItem>
						</Descriptions>
						<Table
							dataSource={authorizationList}
							rowKey="id"
							pagination={{
								pageSize,
								total: count,
								current: currentPage,
								showQuickJumper: false,
								showSizeChanger: true,
								pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
							}}
							onChange={onChangeTable}
							loading={seachLoading}
							style={{ paddingBottom: '15px' }}
						>
							<Column title="" key="id" render={(text) => {
								return <Radio value={text.id} onChange={getNameId} checked={radioId == text.id} />
							}} />
							<Column title="委托书编号" dataIndex="no" />
							<Column title="委托方" dataIndex="client" />
							<Column title="被委托方户名" dataIndex="authorName" />
							<Column title="被委托方账号" dataIndex="authorAccount" />
							<Column title="关系" dataIndex="relation" />
							<Column title="关联主体" dataIndex="companyName" />
							{/* <Column title="委托书" dataIndex="isCopyPowerOfAttorneyCn" /> */}
							<Column title="指定付款协议" dataIndex="isUpPayAgreementCn" />
							<Column title="类型" dataIndex="mixPayAndAttorneyCn" />
							<Column title="备注" dataIndex="remark" width={170} />
							<Column title="状态" key="isRelated" render={(text) => {
								return text.isRelated == 1 ? '已关联' : ''
							}} />
						</Table>
						{authorizationList.length > 0 &&
							<div style={{ margin: "10px 0 20px" }}>
								<Button type="primary" onClick={relateAuthorization}>关联委托书</Button>
							</div>}
						<Descriptions title="" style={{ marginBottom: 0 }} layout="vertical" column={5}>
							<DItem label={<><span className="required-label">*</span> 委托书编号</>}>
								<Input placeholder="请输入" style={{ width: 180 }} onChange={e => setNo(e.target.value)} />
							</DItem>
							<DItem label={<><span className="required-label">*</span> 委托方</>}>
								<Input placeholder="请输入" value={client} style={{ width: 180 }} onChange={e => setClient(e.target.value)} />
							</DItem>
							<DItem label={<><span className="required-label">*</span> 被委托方户名</>}>
								<Input placeholder="请输入" value={authorName} style={{ width: 180 }} onChange={e => setAuthorName(e.target.value)} />
							</DItem>
							<DItem label={<><span className="required-label">*</span> 被委托账号</>}>
								<Input placeholder="请输入" value={authorAccount} style={{ width: 180 }} onChange={e => setAuthorAccount(e.target.value)} />
							</DItem>
							<DItem label={<><span className="required-label">*</span> 关系</>}>
								<Input placeholder="请输入" value={relation} style={{ width: 180 }} onChange={e => setRelation(e.target.value)} />
							</DItem>
						</Descriptions>
						{/* <Descriptions title="" style={{ marginBottom: 0 }} layout="vertical" column={5}>
							<DItem label="委托书">
								<Radio.Group style={{ width: '250px' }} onChange={e => setIsUpPowerOfAttorney(e.target.value)}>
									<Radio value={0}>未提交</Radio>
									<Radio value={1}>已提交</Radio>
								</Radio.Group>
							</DItem>
							<DItem label="类型">
								<Radio.Group style={{ width: '250px' }} onChange={e => setIsCopyPowerOfAttorney(e.target.value)}>
									<Radio value={0}>原件</Radio>
									<Radio value={1}>复印件</Radio>
								</Radio.Group>
							</DItem>
							<DItem label="附件">
								<div className="dragger-box" ref={draggerRkRef}>
									<Upload
										{...uploadFilesRk}
									>
										<Button>
											<Icon type="upload" /> 上传文件
                                        </Button>
										<p className="ant-upload-hint">
											支持扩展名：.rar .zip .doc .docx .pdf .jpg...
                                        </p>
									</Upload>
								</div>
							</DItem>
						</Descriptions> */}
						<Descriptions title="" style={{ marginBottom: 0, marginTop: 8 }} layout="vertical" column={5}>
							<DItem label="指定付款协议">
								<Radio.Group style={{ width: '250px' }} onChange={e => setIsUpPayAgreement(e.target.value)}>
									<Radio value={0}>未提交</Radio>
									<Radio value={1}>已提交</Radio>
								</Radio.Group>
							</DItem>
							<DItem label="类型">
								<Radio.Group style={{ width: '250px' }} onChange={e => setIsCopyPayAgreement(e.target.value)}>
									<Radio value={0}>原件</Radio>
									<Radio value={1}>复印件</Radio>
								</Radio.Group>
							</DItem>
							<DItem label="附件">
								<div className="dragger-box" ref={draggerRkRef2}>
									<Upload
										{...uploadFilesRk2}
									>
										<Button>
											<Icon type="upload" /> 上传文件
                                        </Button>
										<p className="ant-upload-hint">
											支持扩展名：.rar .zip .doc .docx .pdf .jpg...
                                        </p>
									</Upload>
								</div>
							</DItem>
						</Descriptions>
						<Descriptions title="备注" className="pro-detail-smailtitle"></Descriptions>
						<div>
							<Input.TextArea placeholder="请输入" maxLength={100} autosize={{ minRows: 3, maxRows: 6 }} onChange={e => setRemark(e.target.value.trim())}></Input.TextArea>
						</div>
						<div style={{ margin: "10px 0 20px" }}>
							<Button type="primary" onClick={onFortuneAuthorizationAdd}>添加委托书</Button>
						</div>
						<div className="line-box"></div>
					</div>}
					<Descriptions title="附件上传" className="pro-detail-smailtitle"></Descriptions>
					<Form.Item label="">
						<div className="dragger-box" ref={draggerRef}>
							{canEdit ?
								<Dragger
									{...uploadFiles}
								>
									<p className="ant-upload-drag-icon">
										<Icon type="inbox" />
									</p>
									<p className="ant-upload-text">点击或将文件拖拽到这里上传</p>
									<p className="ant-upload-hint">
										附件支持格式：rar、zip、docx、xlsx、bmp、jpg、png 支持添加多个附件，单个附件大小不超过2M，文件数量不超过10个。
                                    </p>
								</Dragger> :
								<div className="content">
									{
										fileList.length ? fileList.map((item, index) => {
											return <div key={index}><Icon style={{ color: '#aaa' }} type='paper-clip' /><a
												href={item.url} target="_blank"
												rel="noopener noreferrer">{item.name}</a></div>
										}) : '无'
									}
								</div>
							}
						</div>
					</Form.Item>
					<div className="line-box"></div>
					<Descriptions title="备注" className="pro-detail-smailtitle"></Descriptions>
					{
						canEdit ?
							<Form.Item label="">
								{getFieldDecorator('remark')(
									<TextArea disabled={!canEdit} maxLength={100} rows={3}></TextArea>
								)}
							</Form.Item>
							:
							detail.remark ? <Form.Item label="">{detail.remark}</Form.Item> : <Form.Item label="">无</Form.Item>
					}

					{logConList.length > 0 &&
						<>
							<div className="line-box"></div>
							<Table
								dataSource={logConList}
								rowKey="id"
								pagination={false}
								style={{ marginTop: 10 }}
							>
								<Table.Column title="申请人/审批人" dataIndex="userInfo.staffName" />
								<Table.Column title="审批时间" dataIndex="addTime" />
								<Table.Column title="状态" dataIndex="status" />
								<Table.Column title="备注" dataIndex="chargeMsg" />
							</Table>
						</>
					}
					<div className="btn-setting">
						<Button hidden={!canEdit} type="primary" htmlType="submit"
							loading={submitLoading} style={{ backgroundColor: '#1890ff' }}>
							{search.id > 0 ? '重新提交' : '提交'}
						</Button>
						{/*<Button hidden={!buttonAuthList.replenish} onClick={onReplenish}>补充资料</Button>*/}
						<Button hidden={!buttonAuthList.return_up} onClick={debounce(() => {onTurnUp()}, 3000)}>转上级</Button>
						<Button hidden={!buttonAuthList.hasChargerPower} onClick={onBtnTurnShow}>驳回</Button>
						<Modal
							// title="确认驳回"
							title={turnNode()}
							visible={turnModalVisible}
							onOk={debounce(() => {onTurnApprove()}, 3000)}
							onCancel={onTurnCancel}
							footer={[
								<Button key="back" onClick={onTurnCancel}>取消</Button>,
								<Button key="submit" type="primary" onClick={debounce(() => {onTurnApprove()}, 3000)}>确定</Button>
							]}
						>
							<div>
								<span>审批意见:</span>
								<Input.TextArea placeholder="请输入驳回审批意见" value={textAreaVal} onChange={onDescChange}
									maxLength={100} />
							</div>
							<span className="poine-text" hidden={textDisplay}>请填写审批意见</span>
						</Modal>
						<Button hidden={!buttonAuthList.hasChargerPower} type="primary"
							onClick={onBtnPassShow}>通过</Button>
						<Modal
							// title="确认通过"
							title={agreeNode()}
							visible={passModalVisible}
							onOk={debounce(() => {onApprove()}, 3000)}
							onCancel={onPassCancel}
							footer={[
								<Button key="back" onClick={onPassCancel}>取消</Button>,
								<Button key="submit" type="primary" onClick={debounce(() => {onApprove()}, 3000)}>确定</Button>
							]}
						>
							<div>
								<span>审批意见:</span>
								<Input.TextArea placeholder="请输入通过审批意见" value={pasDesc} onChange={onPassDescChange}
									maxLength={100} />
							</div>
						</Modal>
						<Popconfirm title="你确定要延期这笔认款么？" cancelText="取消" okText="确认"
							onConfirm={(e) => onBtnDelay()}>
							<Button hidden={!buttonAuthList.hasChargerDelay}>延期</Button>
						</Popconfirm>
					</div>
				</div>
			</Form>
			<DaoKuan canSelectId={canSelectId} selectedDKIds={DKIds} selectDaoKuan={selectDaoKuan}
				daoKuanVisible={daoKuanVisible} setDaoKuanVisible={setDaoKuanVisible} setForbidden={setForbidden} />
		</Spin>
	)
}

export default Form.create()(Detail);