/**
 * 模块名称: 转款申请页
 * @author licongyun@372163.com
 */

import React, { useState, useEffect, useRef } from 'react'
import {
  Table,
  Form,
  Alert,
  Button,
  Input,
  Select,
  message,
  Icon,
  Spin,
  Checkbox,
  Radio,
  Upload,
  Steps,
  Descriptions,
  Tag,
  Tooltip
} from 'antd'
import api from '@/api'
import { parseSearch } from '@/utils'
import { upLoadModule } from '@/utils/common'

const dItem = Descriptions.item
const { Step } = Steps;
const { Column } = Table
const Option = Select.Option
const { Dragger } = Upload;
let pageSize = 10
let currentPage = 1
let pageSize2 = 10
let currentPage2 = 1
let selectedIds = []
let xuanzhongedu = 0
let xuanzhongFlag = 0
let toRelationType = 0
let _toRelationId = 0

const Add = (props) => {
  const { match, location, history } = props
  const { getFieldDecorator, validateFields, setFieldsValue } = props.form
  
  const search = parseSearch(location.search)
  const [transmitData, setTransmitData] = useState({})
  const [customerInfo, setCustomerInfo] = useState({})
  const [count1, setCount1] = useState(0)
  const [count2, setCount2] = useState(0)
  const [statusList, setStatusList] = useState([])
  const [invoiceList, setInvoiceList] = useState([])
  const [transmitToStaff, setTransmitToStaff] = useState([])
  const [accountDetailList, setAccountDetailList] = useState([])
  const [claimList, setClaimList] = useState([])
  const [fundAccountList, setFundAccountList] = useState([])
  const [tranmitToAccountList, setTranmitToAccountList] = useState([])
  const [detailDataId, setdetailDataId] = useState(0)
  const [accountId, setAccountId] = useState('')
  // const [toAccountId, setToAccountId] = useState('')
  const [drawerTitle, setDrawerTitle] = useState('转款申请')
  const [selectedCount, setSelectedCount] = useState(0)
  const [saveLoading, setSaveLoading] = useState(false)
  const [processShow, setProcessShow] = useState(false)
  const [fileList, setFileList] = useState([])
  const [toRelationNameInfo,setToRelationNameInfo] = useState('')
  const [toRelationNameInfos,setToRelationNameInfos] = useState('')
  const [inputDisable, setInputDisable] = useState(false)
  const draggerRef = useRef(null)
  const [selectedFromAccount, setSelectedFromAccount] = useState(0)
  const [selectedToAccount, setSelectedToAccount] = useState(0)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [showLoadingIconForToAccountList, setShowLoadingIconForToAccountList] = useState("")
  const [showLoadingIconForToStaffId, setShowLoadingIconForToStaffId] = useState("")
  const [stateVal, setStateVal] = useState(undefined)
  const [isDisabled, setIsDisabled] = useState('')
  const [isWithInvoice, setIsWithInvoice] = useState(false)
  const [companyIdOut, setCompanyIdOut] = useState('')
  const [pageLoading, setPageLoading] = useState(false)
  const [accountLoading, setAccountLoading] = useState(false)

  useEffect(() => {
    // console.log(search)
    if(search.id > 0){
      setPageLoading(true)
      //驳回重提
      setDrawerTitle('转款驳回重提')
      setProcessShow(true)
      //获取转款详情
      api.fundMoneyTransmitDetail({ id: search.id }).then(data => {
        setTransmitData(data)
        setPageLoading(false)
        setdetailDataId(data.id)
        setSelectedFromAccount(data.fromAccountId)
        setSelectedToAccount(data.toAccountId)

        //获取客户基础信息
        api.getCustomerGetInfo({id: data.fromRelationId, type: data.fromRelationType}, true).then(data => {
          setCustomerInfo(data)
        })
        //审批进度
        setStatusList(data.procedureStatusList ? data.procedureStatusList : [])
        //获取未收款列表
        api.getTransmitInvoiceList({ clientType: search.clientType, clientId: search.clientId, page:currentPage, limit:pageSize }).then(datatmp => {
          setInvoiceList(datatmp.list)
          setCount1(datatmp.count)
        })
        //获取收款信息
        setAccountLoading(true)
        api.getTransmitAccountDetailList({ id: search.id,relationType:search.clientType,relationId:search.clientId, accountId:data.fromAccountId, editFlag: 1, page:currentPage2, limit:pageSize2 }).then(datatmp => {
          setAccountDetailList(datatmp.list)
          setTimeout(() => {
            setClaimList(datatmp.allClaimIdList)
            selectedIds = data.cashIDs.split(',').map(item => +item).filter(num => num > 0)
            setSelectedRowKeys(selectedIds) 
            setAccountLoading(false)
          }, 300)
        })
        //获取转出方主体账户列表
        api.getFundAccountList({relationType:search.clientType, customerId:search.clientId, type:1}).then(data0 => {
          setFundAccountList(data0.list) 
          selectZhuTi(data.fromAccountId, data0.list, 1) //手动触发转出主体，实现反显效果
        })
        
        setProcessShow(true)

        //设置附件信息
        let attachments = eval('(' + data.attachments + ')')
        attachments.forEach((item, index) => {
          fileList.push({
            status: 'done',
            uid: index,
            name: item.name,
            url: item.url
          })
        })
        setFileList(attachments.map((item, index) => ({...item, uid: index})))

        setFieldsValue({
          'money': data.money,
          'remark': data.remark,
          'toRelationType':data.toRelationType,
          'toRelationId':data.toRelationId
        })

        //设置转入信息区域的反显效果
        onChooseRadio(data.toRelationType) //初始化客户类型单选框
        toRelationType = data.toRelationType
        
        setToRelationNameInfo(data.toRelationName + "(ID:" + data.toRelationId + ")")//设置转入ID对应的转入方的名称
        setToRelationNameInfos(data.toRelationName)
        getClientPrincipal(data.toRelationType, data.toRelationId) //查询转入人列表
        
        api.getFundAccountList({relationType:toRelationType, customerId:data.toRelationId, type:1, stfId:data.toStaffId}).then(data2 => { //获取转入主体列表
          setTranmitToAccountList(data2.list)
          selectZhuTi2(data.toAccountId)
        })
        setFieldsValue({
          'toRelationId': data.toRelationId,
          'toStaffId': data.toStaffId,
        })
      })

    } else {
      //转款申请
      setDrawerTitle('转款申请')
      setProcessShow(false)

      search.clientType = search.clientType ? search.clientType : 0;
      search.clientId = search.clientId ? search.clientId : 1;

      //获取客户信息
      api.getCustomerGetInfo({id: search.clientId, type: search.clientType}, true).then(data => {
        setCustomerInfo(data)
        setdetailDataId(0)
        //审批进度
        setStatusList(data.procedureStatusList ? data.procedureStatusList : [])
        //未收款票据
        api.getTransmitInvoiceList({ clientType: search.clientType, clientId: search.clientId, page:currentPage, limit:pageSize }).then(datatmp => {
          setInvoiceList(datatmp.list)
          setCount1(datatmp.count)
        })
        //关联的收款信息
        setAccountLoading(true)
        api.getTransmitAccountDetailList({relationType:search.clientType,relationType:search.clientType,relationId:search.clientId, editFlag: 1, page:currentPage2, limit:pageSize2 }).then(datatmp => {
          setAccountDetailList(datatmp.list)
          setClaimList(datatmp.allClaimIdList)
          setCount2(datatmp.count)
          changeXuanzEDu()
          setAccountLoading(false)
        })
        //转出方的主体资金账号列表
        api.getFundAccountList({relationType:search.clientType, customerId:search.clientId, type:1}).then(data => {
          setFundAccountList(data.list)
        })
      })
    }
  }, [])

  const onChangeTable1 = (pagination, filters, sorter) => {
    currentPage = pagination.current
    pageSize = pagination.pageSize
   // history.replace(`${match.path}?page=${currentPage}&pageSize=${pageSize}`)
    api.getTransmitInvoiceList({clientType: search.clientType, clientId: search.clientId, page:currentPage, limit:pageSize}).then(datatmp => {
      setInvoiceList(datatmp.list)
    })
  }
  //监听claim的变化修改已选中发票额度
  useEffect(() => {
    changeXuanzEDu()
  }, [claimList])

  const changeXuanzEDu = () => {
    xuanzhongedu = 0
    for(var i in claimList){
      for(var j in selectedIds){
        if(claimList[i].id+'' == selectedIds[j]+'' && claimList[i].invoice > 0){
          xuanzhongedu += parseFloat(claimList[i].invoice)
        }
      }
    }
    xuanzhongedu = xuanzhongedu.toFixed(2)
  }

  const onChangeTable2 = (pagination, filters, sorter) => {
    setAccountLoading(true)
    currentPage2 = pagination.current
    pageSize2 = pagination.pageSize 
    //history.replace(`${match.path}?page=${currentPage}&pageSize=${pageSize}`)
    api.getTransmitAccountDetailList({ id: search.id, relationType:search.clientType, relationId:search.clientId, accountId:accountId, editFlag: 1, page:currentPage2, limit:pageSize2}).then(datatmp => {
      setAccountDetailList(datatmp.list)
      setClaimList(datatmp.allClaimIdList)
      setCount2(datatmp.count)
      changeXuanzEDu()
      setAccountLoading(false)
    })
  }

  const rowSelection = {
    getCheckboxProps: (record) => {
      if(!accountId){
        return {disabled: true}
      }else{
        return {disabled: false}
      }
    },
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows)
      setSelectedCount(selectedRows.length)
      selectedIds = selectedRowKeys
      setSelectedRowKeys(selectedIds)
      changeXuanzEDu()
    },
    selectedRowKeys
  }

  //只显示大于零
  const OnClickXuanzhongZero = (e) => {
    xuanzhongFlag = xuanzhongFlag == 0 ? 1 : 0
    console.log(xuanzhongFlag)
    let invoiceMin = xuanzhongFlag ? 0 : null
    setAccountLoading(true)
    api.getTransmitAccountDetailList({ editFlag: 1, invoiceMin: invoiceMin,relationType:search.clientType,relationId:search.clientId, accountId:accountId, page:currentPage2, limit:pageSize2}).then(datatmp => {
      setAccountDetailList(datatmp.list)
      setClaimList(datatmp.allClaimIdList)
      setCount2(datatmp.count)
      changeXuanzEDu()
      setAccountLoading(false)
    })
  }

  //提交表单
  const formSubmits = (e) => {
    e.preventDefault()
    validateFields((err, vals) => {
      selectedIds = selectedIds ? selectedIds : []
      let data = {
        money : vals.money,
        fromRelationType : search.clientType,
        fromRelationId :  search.clientId,
        fromAccountId : vals.fromAccountId,
        toAccountId : vals.toAccountId,
        toRelationType : vals.toRelationType,
        toRelationId : vals.toRelationId,
        toStaffId : vals.toStaffId,
        cashIDs : selectedIds.join(","),
        attachments : [],
        remark : vals.remark,
        withInvoice: vals.withInvoice || vals.withInvoice == 0 ? vals.withInvoice : 1
      }
      if (vals.withInvoice !== 0) {
        if (selectedIds.join(",") == "") {
          message.error("关联的收款信息不能为空")
          setSaveLoading(false)
          return false
        }
      }
      if(vals.fromAccountId == ""){
        message.error("请选择转出主体")
        setSaveLoading(false)
        return false
      }
      if(vals.toAccountId == ""){
        message.error("请选择转入主体")
        setSaveLoading(false)
        return false
      }
      if(Number(stateVal) === 0) {
        message.error("转入公司未实名认证")
        return
      }
      if (vals.toRelationType == search.clientType && vals.toRelationId == search.clientId && vals.toStaffId == customerInfo.creatorId && vals.fromAccountId == vals.toAccountId) {
        return message.error("相同客户、相同主体、相同负责人不能转款")
      }
      fileList.map(item => {
        data.attachments.push(item)
      })
      if (!err) {
        setSaveLoading(true)
        if(detailDataId == 0){ 
          //申请
          api.apply(data).then((res) => {
            setSaveLoading(false)
            console.log(res)
            if (res.status === 'success') {
                message.success(res.message)
                history.push('/fund/moneyTransmit')
                // history.push('/fund/moneyTransmit/detail?id=' +  res.data.transmitId + '&clientId=' + search.clientId + '&clientType=' + search.clientType)
                //setReload({})
            } else {
                message.error(res.message)
            }

          })
          .catch(() => setSaveLoading(false))
        }else{ 
          //驳回重提
          //data.push({id:detailDataId})
          data = {...{id: detailDataId}, ...data}
          api.reApply(data).then((res) => {
            setSaveLoading(false)
            if (res.status === 'success') {
              message.success(res.message)
              history.push('/fund/moneyTransmit')
              // history.push('/fund/moneyTransmit/detail?id=' +  res.data.transmitId + '&clientId=' + search.clientId + '&clientType=' + search.clientType)
              //setReload({})

          } else {
              message.error(res.message)
          }

          })
          .catch(() => setSaveLoading(false))

        }
      }
    })
    return false;
  }
  
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 4 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 20 },
    }
  }

  const selectZhuTi = (id,list, num) => {
    // console.log('转出', id)
    if(num == 0) {
      setSelectedCount(0)
      setSelectedRowKeys([])
      selectedIds = []
      xuanzhongedu = 0
    }
    currentPage2 = 1
    pageSize2 = 10
    setAccountId(id) 
    // console.log(list)
    let listNew = list.filter((v, i) => v.id == id)
    setCompanyIdOut(listNew[0].companyId)
    // console.log('listNew', listNew[0].companyId)
    setAccountLoading(true)
    api.getTransmitAccountDetailList({ id: search.id, relationType:search.clientType, relationId:search.clientId, accountId:id, editFlag: 1, page:currentPage2, limit:pageSize2}).then(datatmp => {
      setAccountDetailList(datatmp.list)
      setClaimList(datatmp.allClaimIdList)
      setCount2(datatmp.count)
      changeXuanzEDu()
      setAccountLoading(false)
    })

    setSelectedRowKeys([])

    let templist = fundAccountList.length == 0 ? list:fundAccountList;
    setFundAccountList( templist.map(item =>  { 
      let showYE = false;
      if(item.id === id){
        showYE = true;
      }
      return {...item, showYE}
    } ))
  }


  const selectZhuTi2 = (id) => {
    // console.log('转入', tranmitToAccountList, '转出', companyIdOut, 'customerInfo.name', customerInfo.name, 'toRelationNameInfos', toRelationNameInfos)
    // console.log(tranmitToAccountList)
    let listNew
    if (tranmitToAccountList.length > 0) {
      listNew = (tranmitToAccountList.filter(v => v.id == id))[0].companyId
    }
    // console.log(listNew)
    if (listNew == companyIdOut && customerInfo.name == toRelationNameInfos) {
      setIsWithInvoice(true)
    } else {
      setIsWithInvoice(false)
    }
    // setToAccountId(id)
    setFieldsValue({
      toAccountId: id
    })
    // console.log(list)
  }


  const MyRadio = (props) => {
    const [showYE, setShowYE] = useState(false);
    const {data} = props
    useEffect(() => {
      setShowYE(data.showYE !== undefined ? data.showYE : false)
    }, [data])
    return <><Radio.Button className="radio-container" value={data.id} disabled={accountLoading ? true : false}>
      <img className="mainNameBodySelLogo" src={data.logo} alt="" />{data.companyName}<Icon className="check" type="check" />
    </Radio.Button>{showYE ? <span style={{marginRight: '10px'}}>余额：{data.balance}</span> : <span>&nbsp;</span>}</>
  }

  // 文件上传
  const uploadFiles = upLoadModule({
    fileList: fileList,
    setFileList: setFileList,
    draggerRef: draggerRef,
    accept: '.rar, .zip, .docx, .xlsx, .bmp, .jpg, .png',
    allowSizeType: 5,
    limitNum: 10
  })

  //转入信息单选赋值
  const onChooseRadio = (val) => {
    toRelationType = val
    setFieldsValue({
      'toRelationId': '',
      'toAccountId':[],
      'toStaffId':''
    })
    setToRelationNameInfo("")
    setToRelationNameInfos("")
    setTranmitToAccountList([])
    setIsWithInvoice(false)
  }

  //转入id输入时触发事件
  const onChangeToRelationId = (e)=>{
    _toRelationId = e.target.value
    // console.log(toRelationType)
    if(toRelationType === '' || toRelationType === null){
      message.error("请先选择将要转入的客户类型")
      return
    }
    setTranmitToAccountList([])
    setToRelationNameInfo("")
    setToRelationNameInfos("")
    setTransmitToStaff([])
    setFieldsValue({
      //'toRelationId': '',
      'toAccountId':[],
      'toStaffId':''
    })
    if(inputDisable || e.target.value == ""){
      return
    }
    setInputDisable(true)
    setToRelationNameInfo(<Spin />)
    //查询客户信息
    getCustomerGetInfo(e.target.value, toRelationType)
    //查询转入人列表
    getClientPrincipal(toRelationType, e.target.value);
  }

  //查询客户信息
  const getCustomerGetInfo = (toRelationId,toRelationType)=>{
    api.getCustomerGetInfo({id: toRelationId, type: toRelationType}, true).then(data => {
      setToRelationNameInfo(data.name + "(ID:" + data.id + ")")
      setToRelationNameInfos(data.name)
      setInputDisable(false)
      setStateVal(data.status)
    }).catch(()=>{
      setToRelationNameInfo("")
      setToRelationNameInfos("")
      setInputDisable(false)
      setStateVal(undefined)
    })
  }
  //查询转入人列表
  const getClientPrincipal = (toRelationType, toRelationId)=>{
    setShowLoadingIconForToStaffId(<Spin />)
    api.getClientPrincipal({clientId: toRelationId, clientType: toRelationType}, true).then(data => {
      //console.log(data)
      setShowLoadingIconForToStaffId('')
      setTransmitToStaff(data)
    }).catch(()=>{
      setTransmitToStaff([])
      setShowLoadingIconForToStaffId('')
    })
  }

  const onChangeForTransmitTo = (staffId) => {
    setFieldsValue({
      toAccountId: ''
    })
    setTranmitToAccountList([])
    setShowLoadingIconForToAccountList(<Spin />)
    //根据负责人id查询提单范围的主体
    api.getFundAccountList({relationType:toRelationType, customerId:_toRelationId, stfId:staffId, type:1}).then(data => {
      // if (toRelationType == search.clientType && _toRelationId == search.clientId && staffId == customerInfo.creatorId) {
      //   data.list.map((v) => {
      //     if (v.id == accountId) setIsDisabled(v.id)
      //   })
      // }
      setShowLoadingIconForToAccountList("")
      setTranmitToAccountList(data.list)
    })
  }

  //带发票额度
  const onChangeWithInvoice = () => {

  }


  return (
    <Spin spinning={pageLoading}>
      <div className="pro-detail">
        <Descriptions title={drawerTitle} className="pro-detail-title" column={5}>
          <dItem label={search.clientType==0 ? 'OAID' : 'QID'}>
            <>
              {customerInfo.id}
            </>
          </dItem>
          <dItem label="客户类型">
            <>
              {customerInfo.typeName}
            </>
          </dItem>
          <dItem label={search.clientType==0 ? "客户名称" : "代理商名称"}>
            <>
              {customerInfo.name}
            </>
          </dItem>
          <dItem label={search.clientType==0 ? "真实性验证号码类型" : "认证号码类型"}>
            <>
              {customerInfo.verifyTypeName}
            </>
          </dItem>
          <dItem label={search.clientType==0 ? "真实性验证号码" : "认证号码"}>
            <>
              {customerInfo.verifyNumber}
            </>
          </dItem>
        </Descriptions>
        <div className="line-box"></div>
        
        {processShow ?
        <Descriptions title="当前审批进度" className="pro-detail-smailtitle"></Descriptions>
        : null}

        {processShow ?
        <div className="promotionProcess" >
          <Steps>
            {
              statusList.map((item, index) => {
                let status = 'wait'
                if (item.time && item.time.length > 1) {
                  status = 'process'
                }
                return <Step status={status}
                  key={index}
                  value={item.time}
                  title={item.nodeName}
                  description={
                    <>
                      {item.persons && <div title={`${item.persons.map((item) => item)}`}>{`${item.persons.map((item) => item)}`}</div>}
                      <div title={item.statusName}>{item.statusName}</div>
                      <div title={item.time}>{item.time}</div>
                    </>
                  }
                />
              })
            }
          </Steps>
        </div>
        : null}

        {processShow ?
        <div className="line-box"></div>
        : null}
      
        <Descriptions title={<><span style={{marginRight: 5}}>未收款票据</span>
          <Tooltip title={<>这里显示的是该客户所有未收款的发票和收据信息</>} placement="right">
            <Icon type="info-circle" theme="twoTone" />
          </Tooltip></>} className="pro-detail-smailtitle">
        </Descriptions>
        <Alert className="corp-count" message={`总计：${count1}条数据`} type="info" showIcon/>
        <Table
          dataSource={invoiceList}
          rowKey="id"
          pagination={{
            pageSize,
            total: count1,
            current: currentPage,
            showQuickJumper: true,
            showSizeChanger: true,
            pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
          }}
          style={{marginBottom: 10}}
          scroll={{x: 1400}}
          onChange={onChangeTable1}
        >
          <Column title="序号" dataIndex="number" />
          <Column title="业务编号" dataIndex="invoiceNo" />
          <Column title="客户/代理商名称" dataIndex="customerName" />
          <Column title="客户类型" dataIndex="customerType" />
          <Column title="主体" dataIndex="companyName" />
          <Column title="金额" dataIndex="money" />
          <Column title="发票类型" dataIndex="type" />
          <Column title="票据号" dataIndex="invoiceNumber" />
          <Column title="状态" dataIndex="stcodetag" />
          <Column title="最后处理时间" dataIndex="updateTime" />
          <Column title="申请人" dataIndex="applyName" />
        </Table>
        <div className="line-box"></div>

        <Descriptions title={<><span style={{color:'red'}}>*</span> <span>转出主体</span></>} className="pro-detail-smailtitle mainNameBodyRadio">
            <Form.Item label="" name="layout">
                {getFieldDecorator('fromAccountId', {"initialValue":selectedFromAccount})(
                  <Radio.Group onChange={(e) => selectZhuTi(e.target.value, fundAccountList, 0)}>
                    {fundAccountList.map(item => <MyRadio key={item.id} data={item} />)}
                  </Radio.Group>
                )}
            </Form.Item>
        </Descriptions>
        <div className="line-box"></div>

        <Descriptions title={<><span style={{color:'red'}}>*</span> <span>关联的收款信息</span></>} className="pro-detail-smailtitle"></Descriptions>
        <Alert className="corp-count" message={`总计：${count2}条数据`} type="info" showIcon/>
        <Table
          dataSource={accountDetailList}
          rowKey="id"
          pagination={{
            pageSize: pageSize2,
            total: count2,
            current: currentPage2,
            showQuickJumper: true,
            showSizeChanger: true,
            pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
          }}
          scroll={{x: 1700}}
          onChange={onChangeTable2}
          rowSelection={rowSelection}
          loading={accountLoading}
        >
          <Column title="序号" dataIndex="id" fixed="left" />
          <Column title="OAID/QID" dataIndex="relationId" />
          <Column title="客户类型" dataIndex="relationTypeCn" />
          <Column title="客户/代理商名称" dataIndex="relationName" />
          <Column title="业务编号" dataIndex="businessNo" />
          <Column title="业务类型" dataIndex="businessTypeCn" />
          <Column title="主体" dataIndex="companyName" />
          <Column title="收入" dataIndex="income" />
          <Column title="可退转额度" dataIndex="remainMoney" />
          <Column title="发票额度支出" dataIndex="cost" />
          <Column title="发票余额" dataIndex="invoice" />
          <Column title="备注" dataIndex="remark" />
          <Column title="负责人" dataIndex="operateStaffName" />
          <Column title="最后处理时间" dataIndex="updateTime" />
        </Table>

        <Descriptions title="" className="pro-detail-smailtitle">
          <dItem label="已选中发票额度">
            <div style={{ color: 'red' }}>
              {xuanzhongedu}
            </div>
          </dItem>
        </Descriptions>

        <Descriptions title="" className="pro-detail-smailtitle">
          <dItem label="仅显示发票余额大于0的收款记录">
            <div onClick={OnClickXuanzhongZero}>
            {/* {getFieldDecorator('edit_xuanzhongZero', { initialValue: '1' })(<Checkbox />)} */}
            <Checkbox />
            </div>
          </dItem>
        </Descriptions>
        <div className="line-box"></div>

        <Descriptions title={<><span style={{color:'red'}}>*</span> <span>转入信息</span></>} className="pro-detail-smailtitle" column={4}>
          <dItem label="客户类型">
              {getFieldDecorator('toRelationType')(
                <Radio.Group>
                    <Radio value={0} onClick={() => onChooseRadio("0")}>直销</Radio>
                    <Radio value={1} onClick={() => onChooseRadio("1")}>代理商</Radio>
                </Radio.Group>
              )}
          </dItem>
          <dItem label="转入ID" span={isWithInvoice ? 1 : 2} className={isWithInvoice ? '' : "dItemTwo"}>
              {getFieldDecorator('toRelationId', {initialValue: ""})(
                <Input disabled={inputDisable} placeholder="" onBlur={onChangeToRelationId} style={{width: 80, marginRight: 10}}/>
              )}    
               {/* <dItem label=""> */}
                 {toRelationNameInfo}  
              {/* </dItem>   */}
          </dItem>
          {
            isWithInvoice && <dItem label="带发票额度">
              {getFieldDecorator('withInvoice', {initialValue: 1})(
                <Radio.Group onChange={onChangeWithInvoice}>
                  <Radio value={1}>是</Radio>
                  <Radio value={0}>否</Radio>
                </Radio.Group>
              )}    
            </dItem>
          }
          
          <dItem label="转入人" className="dItemTwo">
            <span>
              {getFieldDecorator('toStaffId', { initialValue:[] })(
                <Select placeholder={"请选择"} style={{ width: 177 }} onChange={(value) => onChangeForTransmitTo(value)}>
                  {transmitToStaff.map(item => <Option key={item.id} value={item.id} >{item.name}</Option>)}
                </Select>
              )}
            </span>
            <span>
              {showLoadingIconForToStaffId}
            </span>
        </dItem>
         
        </Descriptions>
        <div className="line-box"></div>

        <Descriptions title={<><span style={{color:'red'}}>*</span> <span>转入主体</span></>} className="pro-detail-smailtitle mainNameBodyRadio">
              {/* <Form.Item label="" name="layout">
                  {showLoadingIconForToAccountList}
                  {getFieldDecorator('toAccountId' ,{ initialValue: selectedToAccount })(
                    <Radio.Group onChange={(e) => selectZhuTi2(e.target.value)}>
                      {tranmitToAccountList.map(item => <MyRadio key={item.id} data={item}/>)}
                    </Radio.Group>
                  )}
              </Form.Item> */}
            <Form.Item label="" name="layout">
                {getFieldDecorator('toAccountId', {"initialValue":selectedToAccount})(
                  <Radio.Group onChange={(e) => selectZhuTi2(e.target.value)}>
                    {tranmitToAccountList.map(item => <MyRadio key={item.id} data={item} />)}
                  </Radio.Group>
                )}
            </Form.Item>
        </Descriptions>
        <div className="line-box"></div>

        {/* 表单开始 */}
        <Form onSubmit={formSubmits} {...formItemLayout}>
          <Descriptions title="" className="pro-detail-smailtitle" column={1}>
            <dItem label={<><span style={{color:'red'}}>*</span> <span>转款金额</span></>}>
              <Tooltip title={<>1.转款金额不能大于主体可用余额<br/>2.转出后将优先从较早的收款信息上扣减发票余额</>} placement="right">
                <Icon type="info-circle" theme="twoTone" />
              </Tooltip>
            </dItem>
            <dItem>
              <Form.Item style={{marginBottom: 0}}>
                  {getFieldDecorator('money', {
                      rules: [{ required: true, message: '请输入转款金额' }]
                    })(<Input placeholder="请输入"/>)}
              </Form.Item>
            </dItem>
          </Descriptions>

          <Descriptions title="附件上传" className="pro-detail-smailtitle"></Descriptions>
          <Form.Item label="">
              <div className="dragger-box" ref={draggerRef}>
                  <Dragger
                      {...uploadFiles}
                  >
                      <p className="ant-upload-drag-icon">
                          <Icon type="inbox"/>
                      </p>
                      <p className="ant-upload-text">点击或将文件拖拽到这里上传</p>
                      <p className="ant-upload-hint">
                          附件支持格式：rar、zip、docx、xlsx、bmp、jpg、png 支持添加多个附件，单个附件大小不超过2M，文件数量不超过10个。
                      </p>
                  </Dragger>
              </div>
          </Form.Item>
          <Form.Item>
            <Descriptions title="" className="pro-detail-smailtitle" column={1}>
              <dItem label="备注">
                  
              </dItem>
              <dItem>
                {
                  getFieldDecorator('remark', { 
                    rules: [{ required: false, message: '请输入备注' }]
                  })(<Input.TextArea placeholder="请输入备注" rows={3} cols={250}/>)
                }
              </dItem>
            </Descriptions>
          </Form.Item>
          {search.id ? 
            <Descriptions title="" className="pro-detail-smailtitle">
              <dItem label="当前状态">
                <Tag color="#2db7f5">{transmitData.stCodeName}</Tag>
              </dItem>
            </Descriptions>
          : null}

          {/* <BtnGroup confirmName="重新提交" loading={saveLoading} /> */}

          {/* <Form.Item> */}
            <Button type="primary" loading={saveLoading} htmlType="submit">
              { search.id > 0 ? '重新提交' : '提交'}
            </Button>
          {/* </Form.Item> */}

        </Form>
      </div>
    </Spin>
  )
}

export default Form.create()(Add)