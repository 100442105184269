/**
 * 模块名称: 资金管理 => 新老客户对应表
 * @author zhuyan@372163.com
 */

 import React from 'react'
 import { Switch, Route } from 'react-router-dom'
 import Compare from './Compare'
 
 const Funds = ({ match, history }) => {
   return (
     <Switch>
       <Route path={match.path + '/compare'} component={Compare} />
       {/* <Route component={Compare} /> */}
     </Switch>
   )
 }
 
 export default Funds