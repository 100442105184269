/**
 * 模块名称: 转款管理
 * @author licongyun@372163.com
 */

import React, { useState, useEffect, useRef } from 'react'
import api from '@/api'
import {
  Table,
  Form,
  Alert,
  Button,
  Input,
  Select,
  message,
  Icon,
  Row,
  Col,
  Modal,
  DatePicker, 
  Popconfirm, 
  Drawer,
  Switch,
  Tooltip
} from 'antd'

import { parseSearch } from '@/utils'
import CorpFilter from '@/components/CorpFilter'
import Export from '@/components/Export'
import moment from 'moment'
import {Link} from "react-router-dom"
import {useSelector} from "react-redux"
import Auth from '@/components/AuthMiddleware'
import CryptoJS from 'crypto-js'

const { Column } = Table
const FormItem = Form.Item
const Option = Select.Option
const { RangePicker } = DatePicker
let pageSize = 10
let currentPage = 1
let shouldMe = null
let apiOperateTimer = true

let allFilter = {}

let filter = {
  companyId: ''
}

let exporfilter = {

}

let selectedIds = ''
let params = {}
let _toRelationType = ''
let _toRelationId = ''
let _fromRelationType =''
let _fromRelationId =''

const dateFormat = 'YYYY-MM-DD'

const Transmit = (props) => {
  const { match, location, history } = props
  const { getFieldDecorator, validateFields, resetFields, setFieldsValue, getFieldsValue } = props.form

  const productsRef = useRef()

  const [loading, setLoading] = useState(false)
  const [list, setList] = useState([])
  const [count, setCount] = useState(0)
  const [expand, setExpand] = useState(false)
  const [showExport, setShowExport] = useState(false)
  const search = parseSearch(location.search)
  const [statusVal, setStatusVal] = useState(undefined)
  const [filterContent, setFilterContent] = useState([])
  const [filterInfoValue, setFilterInfoValue] = useState('')
  const [visible, setVisible] = useState(false)
  const [checked, setChecked] = useState(0)
  const [checkedIdArr, setCheckedIdArr] = useState([])
  const [typeList, setTypeList] = useState([])
  const [faPiaoYuEListVisible, setFaPiaoYuEListVisible] =  useState(false)
  const [faPiaoYuEList, setFaPiaoYuEList] = useState([])
  const [authList, setAuthList] = useState([])
  const [statusValName, setStatusValName] = useState(undefined)
  const [flag, setFlag] = useState(false)
  const [shouldMeDis, setShouldMeDis] = useState(true)//风控，防止用户频繁点击switch

  const userInfo = useSelector(state => state.userInfo)//获取当前登录信息

  useEffect(() => {
    _toRelationType = search.toRelationType ?  search.toRelationType : ''
    _toRelationId = search.toRelationId ?  search.toRelationId : ''
    _fromRelationType = search.fromRelationType ?  search.fromRelationType : ''
    _fromRelationId = search.fromRelationId ?  search.fromRelationId : ''
    api.getPageAuth().then(list => setAuthList(list))
  }, [])

  useEffect(() => {
    if (!history.location.state) {
      currentPage = search.page ? + search.page : 1
      pageSize = search.pageSize ? + search.pageSize : 10
      filter.startDate = ''
      filter.endDate = ''
      filter.fromRelationId = ''
      filter.fromOldRelationId= ''
      filter.fromRelationName = ''
      filter.fromRelationType = ''
      filter.lastTime = ''
      filter.no = ''
      filter.stCode = ''
      filter.stfName = ''
      filter.toRelationId = ''
      filter.toOldRelationId= ''
      filter.toRelationName = ''
      filter.fromRelationId = search.fromRelationId ?  search.fromRelationId : ''
      filter.fromRelationType = search.fromRelationType ?  search.fromRelationType : ''
      filter.toRelationId = search.toRelationId ?  search.toRelationId : ''
      filter.toRelationType = search.toRelationType ?  search.toRelationType : ''
    } else {
      currentPage = search.page ? +search.page : currentPage
      pageSize = search.pageSize ? +search.pageSize : pageSize
      // console.log(filter)
      setTimeout(() => {
        setFieldsValue({
          fromRelationName: filter.fromRelationName ? filter.fromRelationName : undefined,
          fromRelationId: filter.fromRelationId ? filter.fromRelationId : undefined,
          fromOldRelationId: filter.fromOldRelationId ? filter.fromOldRelationId : undefined,
          toRelationName: filter.toRelationName ? filter.toRelationName : undefined,
          toRelationId :  filter.toRelationId ? filter.toRelationId : undefined,
          toOldRelationId :  filter.toOldRelationId ? filter.toOldRelationId : undefined,
          fromRelationType: filter.fromRelationType ? filter.fromRelationType : undefined,
          stCode: filter.stCode ? filter.stCode : undefined,
          no: filter.no ? filter.no : undefined,
          stfName: filter.stfName ? filter.stfName : undefined,
          date: filter.startDate ? [moment(filter.startDate, dateFormat), moment(filter.endDate, dateFormat)] : undefined
        })
      }, 1000)
    }
    shouldMe = null
    apiOperateTimer = true
    getList()
    api.getCommonWxTab({dataType:2, pageType:1}).then(data => setTypeList(data.list))
  }, [])

  const getList = () => {
    if(apiOperateTimer){
      setLoading(true)
      if(shouldMe){
        filter.shouldMe = shouldMe
      }else{
        for(let i in filter){
          if(i == 'shouldMe')delete filter[i]
        }
      }
      api.getFundMoneyTransmitList({ 
        ...filter, 
        limit: pageSize, 
        page: currentPage
      })
      .then(data => {
        // console.log(data)
        setLoading(false)
        setList(data.list)
        setCount(data.count)
      })
      .catch(() => setLoading(false))
      apiOperateTimer = false
      setShouldMeDis(false)
      let timer = setTimeout(() => {
        apiOperateTimer = true
        setShouldMeDis(true)
        clearTimeout(timer)
      }, 1000)
    }else{
      return message.info('请不要频繁操作')
    }
  }

  const onSetShouldMe = e => {
    if(e == true){
      shouldMe = 1
    }else{
      shouldMe = null
    }
    getList()
  }

  const showFaPiaoYuEMingXi = (id) => {
    api.fundMoneyTransmitGetInvoiceDetailByDetailId({transmitId: id}).then((res) => {
      setFaPiaoYuEListVisible(true)
      setFaPiaoYuEList(res)
    })
  }

  // 查询
  const onSearch = (e) => {
    e.preventDefault()
    validateFields((err, vals) => {
      if (!err) {
        if(vals.date){
          delete vals.date
        }
        filter = { ...filter, ...vals }
        history.replace(match.path)
        currentPage = 1
        getList()
      }
    })
  }
  
  const onChangeTable = (pagination, filters, sorter) => {
    currentPage = pagination.current
    pageSize = pagination.pageSize
    history.replace(`${match.path}?page=${currentPage}&pageSize=${pageSize}&toRelationType=${_toRelationType}&toRelationId=${_toRelationId}&fromRelationType=${_fromRelationType}&fromRelationId=${_fromRelationId}`)
    getList()
  }

  const onChangeCorp = (data) => {
    setChecked(0)
    setCheckedIdArr([])
    filter.companyId = data.map(item => item.id).join(',')
    history.replace(match.path)
    currentPage = 1
    getList()
  }

  const onToggle = () => {
    setExpand(!expand)
  }

  const onReset = () => {
    resetFields()
    shouldMe = null
    filter.startDate = ''
    filter.endDate = ''
    filter.fromRelationId = ''
    filter.fromOldRelationId = ''
    filter.fromRelationName = ''
    filter.fromRelationType = ''
    filter.lastTime = ''
    filter.no = ''
    filter.stCode = ''
    filter.stfName = ''
    filter.toRelationId = ''
    filter.toOldRelationId = ''
    filter.toRelationName = ''
    setStatusVal(undefined)
    setStatusValName(undefined)
    history.replace(match.path)
    currentPage = 1
    getList()
  }

  // 弹出框确定
  const HandleOk = () => {
    api.saveSearchStorage({
      name: filterInfoValue,
      key: location.pathname,
      content: params
    }).then(data => {
      setVisible(false)
      message.success('添加成功')
    })
  }

  const HandleCancel = () => {
    setVisible(false) //弹出框内取消
  }
  
  const FilterInfoChange = (e) => {
    setFilterInfoValue(e.target.value) //弹框input值
  }

  const onChangeGetFilter = (val) => {
    val ? setFlag(true) : setFlag(false)
    // 下拉框搜索内容
    api.getSearchStorage({ key: location.pathname }).then(data => {
      setFilterContent(data.list)
    })
  }

  const onChecked = (value, val2) => {
    setStatusVal(value)
    setStatusValName(val2.props.children.props.children[0])
    // let data = JSON.parse(value)
    let data = filterContent[value].content
    let fields = getFieldsValue()
    let params = {};
    delete fields['date']

    for(let i in fields){
      params[i] = data[i] ? data[i] : undefined
    }
    params['date'] = data.startDate ? [moment(data.startDate, dateFormat), moment(data.endDate, dateFormat)] : undefined

    setFieldsValue(params)

    if( data.startDate ){
      filter.startDate = data.startDate
      filter.endDate = data.endDate
    }else{
      filter.startDate = ''
      filter.endDate = ''
    }
  }
  
  const onSaveFilterTerm = () => {
    validateFields((err, vals) => {
      if (!err) {
        const values = Object.values(vals).map(item => item == undefined ? '' : item)
        // console.log(vals, values)
        if (values.some(item =>  item !== '' )) {
          params = {}
          if(vals.date && vals.date.length){
            params.startDate = moment(vals.date[0]).format(dateFormat)
            params.endDate = moment(vals.date[1]).format(dateFormat)
          }

          for (let k in vals) {//eslint-disable-line
            if (vals[k] !== undefined) {
              params[k] = vals[k]
            }
          }

          setVisible(true)
        } else {
          message.error('当前没有设置筛选条件')
        }
      }
    })
  }

  const onDelete = (id) => {
    api.fundMoneyTransmitDelete({
      id: id
    }).then(res => {
      message.success('删除成功')

      const index = checkedIdArr.indexOf(id);
      if(index > -1){
        checkedIdArr.splice(index, 1);
        updateSelected(checkedIdArr)
      }
      getList()
    })
  }

  //导出
  const onExportFile = () => {
    setShowExport(true)
    if (filter.companyId && filter.companyId.length > 0) {
      if (!Array.isArray(filter.companyId)) {
        filter.companyId = filter.companyId.split(',')
      }
    } else {
      filter.companyId = []
    }
    Object.assign(allFilter, filter, exporfilter)
  }

  const onChangePicker = (data) => {
    if (data.length === 0) {
      filter.startDate = ''
      filter.endDate = ''
    } else {
      filter.startDate = moment(data[0]).format(dateFormat)
      filter.endDate = moment(data[1]).format(dateFormat)
    }
  }

  const rowSelection = {
    onSelect: (record, selected) => {
      if (!selected) {
        checkedIdArr.splice(checkedIdArr.indexOf(record.id), 1);
      } else {
        checkedIdArr.push(record.id)
      }
      updateSelected(checkedIdArr)
    },

    onSelectAll: (selected, selectedRows, changeRows) => {
      // console.log(selected, selectedRows, changeRows)
      if(selected){
        changeRows.map( item => checkedIdArr.push(item.id))
      }else{
        changeRows.map( item => checkedIdArr.splice(checkedIdArr.indexOf(item.id), 1))
      }
      updateSelected(checkedIdArr)
    },
    selectedRowKeys: checkedIdArr
  }

  const updateSelected = (checkedIdArr) => {
    setCheckedIdArr(checkedIdArr)
    setChecked(checkedIdArr.length)
    selectedIds = checkedIdArr
  }

  // 筛选删除
  const onSearchDelete = (id) => {
    api.searchStorageDelete({
      id: id
    }).then(() => {
      api.getSearchStorage({ key: location.pathname }).then(data => {
        setFilterContent(data.list)
      })
    })
  }

  const relationTypeList = [
    {
      id : 0,
      name: '直销',
    },
    {
      id : 1,
      name: '代理商',
    }
  ]

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 12 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 12 },
    }
  }

  return (
    <>
      <CorpFilter onChange={onChangeCorp} />
      <Form onSubmit={onSearch} {...formItemLayout} className="ant-advanced-search-formTwo">
        <div className="search-item-wrap">
          <div className="search-item">
            <Row gutter={24}>
              <Col span={8}>
                <FormItem label="转出客户/代理商名称" labelCol={{span: 12}}>
                  {getFieldDecorator('fromRelationName', { initialValue: '' })(<Input placeholder="请输入" />)}
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem label="转出XOAID/XQID" labelCol={{span: 12}}>
                  {getFieldDecorator('fromRelationId', { initialValue: '' })(<Input placeholder="请输入" />)}
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem label="转出老ID" labelCol={{span: 12}}>
                  {getFieldDecorator('fromOldRelationId', { initialValue: '' })(<Input placeholder="请输入" />)}
                </FormItem>
              </Col>
              
            </Row>
            <Row gutter={24} className={expand?'':'hide'}>
              <Col span={8}>
                <FormItem label="转入客户/代理商名称" labelCol={{span: 12}}>
                  {getFieldDecorator('toRelationName', { initialValue: '' })(<Input placeholder="请输入" />)}
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem label="转入XOAID/XQID" labelCol={{span: 12}}>
                  {getFieldDecorator('toRelationId', { initialValue: '' })(<Input placeholder="请输入" />)}
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem label="转入老ID" labelCol={{span: 12}}>
                  {getFieldDecorator('toOldRelationId', { initialValue: '' })(<Input placeholder="请输入" />)}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={24} className={expand?'':'hide'}>
              <Col span={8}>
                <FormItem label="客户类型（转出）" labelCol={{span: 12}}>
                  {getFieldDecorator('fromRelationType')(
                      <Select placeholder="请选择">
                        {relationTypeList.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                      </Select>
                  )}
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem label="转款状态" labelCol={{span: 12}}>
                  {getFieldDecorator('stCode')(
                      <Select placeholder="请选择">
                        {typeList.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                      </Select>
                  )}
                </FormItem>
              </Col> 
              <Col span={8}>
                <FormItem label="转款编号" labelCol={{span: 12}}>
                  {getFieldDecorator('no', { initialValue: '' })(<Input placeholder="请输入" />)}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={24} className={expand?'':'hide'}>
              <Col span={8}>
                <FormItem label="申请人" labelCol={{span: 12}}>
                  {getFieldDecorator('stfName', { initialValue: '' })(<Input placeholder="请输入" />)}
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem label="最后处理时间" labelCol={{span: 12}}>
                  {getFieldDecorator('date')(
                      <RangePicker onChange={onChangePicker} />
                  )}
                </FormItem>
              </Col>
            </Row>
          </div>
          <span className={`expand-btn${expand ? ' show-all' : ''}`} onClick={onToggle} style={{cursor: 'pointer', fontSize: '12px', color: '#1890ff'}}>
            <span>{expand ? '收起' : '展开'} <Icon type={expand ? 'up' : 'down'} /></span>
          </span>
        </div>
        <div className="search-btns" style={{paddingBottom: 10}}>

        <div className={`search-btns ${expand ? '' : 'hide'}`}>
            <h4 className="filter-title">已保存筛选方案：</h4>
            <div className="filter-select">
              <Select
                style={{ display: 'inline-block', width: '160px' }}
                placeholder="请选择"
                value={statusVal}
                onDropdownVisibleChange={bool => bool && onChangeGetFilter(bool)}
                onSelect={onChecked}
                dropdownMatchSelectWidth={false}
              >
                {
                  filterContent.map((item, index) =>
                    <Option value={index} key={index}>
                      <div className="closeStyBtn">
                        {item.name}
                        {statusValName !== item.name ? <span className="closeSpan"><Icon style={{ fontSize: '10px', color: '#1890ff' }} type="close" onClick={e => {
                          e.stopPropagation()
                          onSearchDelete(item.id)
                        }} /></span> : null}
                      </div>
                    </Option>
                  )
                }
              </Select>
            </div>
            <Button className="filter-savebtn" onClick={onSaveFilterTerm} style={{ width: 130, margin:'0 15px' }}>保存筛选条件</Button>
          </div>
          <Auth auths={authList} code="speciel-money-transform">
            <Link to={`/fund/moneyTransmit/specialAdd`}>
              <Button style={{ marginRight: 10 }} onClick={onReset}>特殊转款</Button>
            </Link>
          </Auth>
          <Switch 
            checkedChildren="待审批" 
            unCheckedChildren="待审批" 
            disabled={!shouldMeDis} 
            onClick={onSetShouldMe} 
            className="shouldme-switch-btn" 
            checked={shouldMe ? true : false} 
          />
          <Button onClick={onReset}>重置</Button>
          <Button style={{ marginLeft: 8 }} type="primary" htmlType="submit">查询</Button>
          <Modal
            title="保存筛选条件"
            visible={visible}
            onOk={HandleOk}
            onCancel={HandleCancel}
          >
            <Input placeholder="请输入筛选名称" value={filterInfoValue} onChange={FilterInfoChange} />
          </Modal>
        </div>
      </Form>
      <div className="line"></div>
      <div className="operate-wrap">
        <Row  type="flex" justify="end">
    
          <Col span={3}>
            {
              (() => {
                //
                // if(!authList.includes('export')){
                //   return;
                // }
                return (
                  <Auth auths={authList} code="export">
                    <Button type="primary" icon="upload" onClick={onExportFile} style={{ marginLeft: 15 }}>导出</Button>
                  </Auth>
                )
              })()
            }
          </Col>
        </Row>

      </div>
      <Alert className="count-alert" message={
        <>
          <span>已选择 <span style={{color: '#168FFF'}}>{checked}</span> 项</span>
          <span style={{ marginLeft: 20 }}>总共{count}条数据</span>
        </>
      } type="info" showIcon/>
      <Table
        size="middle"
        dataSource={list}
        rowKey="id"
        className="table-page"
        loading={loading}
        pagination={{
          pageSize,
          total: count,
          current: currentPage,
          showQuickJumper: true,
          showSizeChanger: true,
          pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
        }}
        scroll={{x: 'max-content'}}
        onChange={onChangeTable}
        rowSelection={rowSelection}
      >
        <Column title="序号" fixed="left" render={
          (text,record,index) => {
            return (currentPage - 1)*pageSize + index + 1
          }
        } />
        <Column title="转款编号" dataIndex="no" fixed="left" render={(text, record) =>
          <>
            {
              record.transferType == 1 || record.transferType == 2 || record.transferType == 3 ? <div>{text}</div> :
              record.stfId === userInfo.id && record.busCode == 3 ? 
              (record.isSpecial === 1 ? <Link to={`${match.path}/specialAdd?id=${record.id}&clientId=${record.fromRelationId}&clientType=${record.fromRelationType}&new=1`}>{text}</Link>
              :<Link to={`${match.path}/add?id=${record.id}&clientId=${record.fromRelationId}&clientType=${record.fromRelationType}&new=1`}>{text}</Link>
              )
              : <Link to={`${match.path}/details?id=${record.id}&clientId=${record.fromRelationId}&clientType=${record.fromRelationType}&new=1&time=${+new Date()}&sign=${CryptoJS.MD5(record.id+'ad629fddf8b8756d2e72e96ae035a5a4'+(+new Date())).toString()}`}>{text}</Link>
            }
          </>
        } />
        {/* <Column title="业务类型" dataIndex="businessType" fixed="left" /> */}
        <Column title="客户/代理商名称" fixed="left" width={180}dataIndex="fromRelationName" />
        <Column title="XOAID/XQID（转出）" dataIndex="fromRelationId" />
        <Column title="老ID（转出）" dataIndex="fromOldRelationId" />
        <Column title="客户类型" dataIndex="fromRelationTypeName" render={(text, record) => 
          record.isSpecial === 1 ? <span style={{color:'red'}}>{`${record.fromRelationTypeName}`}</span> :
            <span>{record.fromRelationTypeName}</span>
        } />
        <Column title="主体" dataIndex="fundAccountInfo.fromAccount.companyName" />
        <Column title="XOAID/XQID（转入）" dataIndex="toRelationId" />
        <Column title="老ID（转入）" dataIndex="toOldRelationId" />
        <Column title="客户/代理商名称" dataIndex="toRelationName" />
        <Column title="客户类型" dataIndex="toRelationTypeName" />
        <Column title="主体" dataIndex="fundAccountInfo.toAccount.companyName" />
        <Column title="转入负责人" dataIndex="fundAccountInfo.toAccount.staffName" />
        <Column title="金额" dataIndex="money" />
        <Column title="发票余额"
              render = {(record) => {
                return (
                  record.busCode == 2 ? <a className="operate-icon" onClick={() => showFaPiaoYuEMingXi(record.id)}>{record.invoiceInfo}</a> : ''
              

                )
              }}
        />
        <Column title="状态" dataIndex="stCodeName" />
        <Column title="申请人" dataIndex="stfName" />
        <Column title="负责人" dataIndex="managerName" />
        <Column title="资金平移" render = {(record) => {
            return (
              record.transferType == 0 ? '否' : '是'
            )
        }}/>
        <Column title="带发票额度" 
          render = {(record) => {
            return (
              record.withInvoice == 1 ? '带' : '不带'
            )
        }}
        />
        <Column title="备注" dataIndex="remark" />
        <Column title="最后处理时间" dataIndex="lastTime" />
        <Column title="操作" fixed="right" 
          key="set"
          width={120}
          render={(record) =>{
              let del = null
              let detailNew = null

              if(record.busCode === 3){
                  del = (
                    <>
                        <Popconfirm title="请确认是否删除，删除后不可恢复。" cancelText="取消" okText="确认" onConfirm={(e) => onDelete(record.id)}>
                            <a className="operate-icon" type="delete" >删除</a>
                        </Popconfirm>
                    </>
                )
              }
              detailNew = (
                <>
                  <Tooltip title="在新页面打开详情页" placement="bottom">
                    {
                      record.stfId === userInfo.id && record.busCode == 3 ? 
                      (record.isSpecial === 1 ?
                         <Link target='_blank' to={`${match.path}/specialAdd?id=${record.id}&clientId=${record.fromRelationId}&clientType=${record.fromRelationType}`}>新页面</Link>:
                         <Link target='_blank' to={`${match.path}/add?id=${record.id}&clientId=${record.fromRelationId}&clientType=${record.fromRelationType}`}>新页面</Link>
                      )
                      : <Link target='_blank' to={`${match.path}/details?id=${record.id}&clientId=${record.fromRelationId}&clientType=${record.fromRelationType}&time=${+new Date()}&sign=${CryptoJS.MD5(record.id+'ad629fddf8b8756d2e72e96ae035a5a4'+(+new Date())).toString()}`}>新页面</Link>
                    }
                  </Tooltip>
                </>
              )
              return (
                  <>
                    {record.transferType == 1 || record.transferType == 2 || record.transferType == 3 ? '' : detailNew}
                    {del}
                  </>
              )
            }

          }
        />
      </Table>


      <Drawer
          title="发票余额明细"
          width={1000}
          onClose={() => setFaPiaoYuEListVisible(false)}
          visible={faPiaoYuEListVisible}
      >
        <Table
            dataSource={faPiaoYuEList}
            rowKey="id"
            className="table-page"
            scroll={{x: 1000}}
        >
          <Column title="序号" dataIndex="id" />
          <Column title="业务编号" dataIndex="businessNo" />
          <Column title="业务类型" dataIndex="type" />
          <Column title="本次扣减" render = {(record) => {
                  return (
                       record.changeMoney > 0 ? '' : record.changeMoney
                  )
                }}/>
          <Column title="本次增加" render = {(record) => {
                  return (
                       record.changeMoney > 0 ? record.changeMoney : ''
                  )
                }}/>
          <Column title="发票余额" dataIndex="remainBalance" />
          <Column title="关联主体" dataIndex="companyName" />
          <Column title="业务状态" dataIndex="businessStatus" />
          <Column title="申请人" dataIndex="stfName" />
          <Column title="最后处理时间" dataIndex="date" />
        </Table>
      </Drawer>

      <Export
          show={showExport}
          onCancel={() => {
            setShowExport(false)
            productsRef.current.changeVal()
          }}  
          tplUrl="getFundMoneyTransmitExportTpl"
          fieldsUrl="getFundMoneyTransmitTplItems"
          saveUrl="saveFundMoneyTransmitExportTpls"
          exportUrl="fundMoneyTransmitExport"
          method="export"
          parame={{ ExportType: 'fundmoneytransmit.export', id: selectedIds, ...allFilter }}
          type={1}
          cRef={productsRef}
      />

    </>
  )
}

export default Form.create()(Transmit)