/**
 * 模块名称: 转款详情页
 */

import React, {useState, useEffect, useRef} from 'react'
import {
    Descriptions,
    Steps,
    Button,
    Modal,
    Input,
    Icon,
    Form,
    Radio,
    message,
    Tag,
    Spin, 
    Table
} from 'antd'
import api from '@/api'
import {parseSearch} from '@/utils'
import CryptoJS from 'crypto-js'
import {Link} from "react-router-dom"

const { Column } = Table
const DItem = Descriptions.Item
const {Step} = Steps

let id = 0, collectedBills_currentPage = 1, uncollectedBills_currentPage = 1
let limit1 = 10, page1 = 1
let limit2 = 10, page2 = 1

let selectDaoKuanCB = null
let _fundAccountList = []
let userInfo = null
let disabled = true

const Detail = (props) => {
    const { setFieldsValue } = props.form
    const { location, history } = props
    const [clientInfo, setClientInfo] = useState({})
    const [detailDataId, setDetailDataId] = useState(0)
    const [detailData, setDetailData] = useState({})
    // const [data, setData] = useState({})
    const [logConList, setLogConList] = useState([])
    const [statusList, setStatusList] = useState([])
    const [buttonAuthList, setButtonAuthList] = useState([])
    const [passModalVisible, setModalVisible] = useState(false)
    const [turnModalVisible, setTurnModalVisible] = useState(false)
    const [textAreaVal, setTextAreaVal] = useState('')
    const [textDisplay, setTextDisplay] = useState(true)
    const [pasDesc, setPasDesc] = useState('')
    const [fundAccountList, setFundAccountList] = useState([]);
    const [RKForms, setRKForms] = useState([])
    const [daoKuanVisible, setDaoKuanVisible] = useState(false);
    const [relationPro, setRelationPro] = useState('');
    const [showRelationPro, setShowRelationPro] = useState(false);
    const [ZKTotal, setZKTotal] = useState(0)
    const [DKIds, setDKIds] = useState([])
    const [canSelectId, setCanSelectId] = useState(0)
    const [fileList, setFileList] = useState([])
    const [RKZhuti, setRKZhuti] = useState(0)
    const [canEdit, setCanEdit] = useState(true)
    const [detailLoading, setDetailLoading] = useState(true)
    const [reload, setReload] = useState({})

    const search = parseSearch(location.search)
    const draggerRef = useRef(null)

    const [uncollectedBills, setUncollectedBills] = useState([]) //未收款票据列表list
    const [collectedBills, setCollectedBills] = useState([]) //收款信息列表list

    let pageSize = 10
    const [uncollectedBills_loading, setUncollectedBills_loading] = useState(false)
    const [uncollectedBills_count, setUncollectedBills_count] = useState(0)

    const [collectedBills_loading, setCollectedBills_loading] = useState(true)
    const [collectedBills_count, setCollectedBills_count] = useState(0)

    const [accountDetailList, setAccountDetailList] = useState([])
    const [initialData, setInitialData] = useState({})

    id = search.id
    useEffect(() => { 
        let plaintext = search.sign
        let plaintextTwo = CryptoJS.MD5(search.id + 'ad629fddf8b8756d2e72e96ae035a5a4' + search.time).toString()
        
        if (plaintext == plaintextTwo && +new Date() - +search.time <= 21600000) {
            // console.log(search.new)
            limit1 = 10; page1 = 1
            limit2 = 10; page2 = 1
            if (search.id) {
                api.fundMoneyTransmitDetail({ id: search.id }, true).then(data => {
                    if (search.msgId) {
                        api.setMessageReaded({ ids: [search.msgId] })
                    }
                    setInitialData(data)
                    const apis = [
                        api.getCustomerGetInfo({id: data.fromRelationId, type: data.fromRelationType}),
                        api.getFundAccountList({relationType: data.fromRelationType, customerId: data.fromRelationId, type: 1}),
                        api.getUser(),
                        getUncollectedBillsList(data.fromRelationType, data.fromRelationId), //未收款票据
                        getTransmitAccountDetailList()//关联的收款信息
                    ]
                    setDetailLoading(true)
                    Promise.all(apis).then(res => {
                            const _clientInfo = res[0]
                            setClientInfo(_clientInfo)
                            _fundAccountList = res[1]
                            setFundAccountList(_fundAccountList.list)
                            userInfo = res[2]
                            setUncollectedBills(res[3])
                            setCollectedBills(res[4])
                            getDetail(search.id)
                        })
                })
            } else {
                const apis = [
                    api.getCustomerGetInfo({id: search.clientId, type: search.clientType}),
                    api.getFundAccountList({relationType: search.clientType, customerId: search.clientId, type: 1}),
                    api.getUser(),
                    getUncollectedBillsList(), //未收款票据
                    getTransmitAccountDetailList()//关联的收款信息
                ];
                setDetailLoading(true)
                Promise.all(apis).then(res => {
                        const _clientInfo = res[0]
                        setClientInfo(_clientInfo)
                        _fundAccountList = res[1]
                        setFundAccountList(_fundAccountList.list)
                        userInfo = res[2]
                        setUncollectedBills(res[3])
                        setCollectedBills(res[4])
                        getDetail(search.id)
                    })
        
            }
        } else {
            message.error('没有本页访问权限')
            setTimeout(() => {
            history.go(-2)
            }, 1000)
        }
    }, [reload])

    
    // useEffect(() => {
    //     collectedBills_currentPage = 1
    //     uncollectedBills_currentPage = 1
    //     limit1 = 20
    //     page1 = 1
    //     limit2 = 20
    //     page2 = 1
    // }, [])

    //获取转款详情接口
    const getDetail = (id) => {
        api.fundMoneyTransmitDetail({id}).then(
            detail => {
                
                setDetailData(detail)
                //console.log(detailData.fromCustomerInfo)
                setDetailDataId(detail.id)
                setStatusList(detail.procedureStatusList ? detail.procedureStatusList : []) // 审批进度
                setLogConList(detail.procedureLogList) // 审批日志
                setButtonAuthList(detail.buttonAuthList) // 审批权限

                setFieldsValue({accountId: detail.accountId})
                _fundAccountList.list = _fundAccountList.list.map(item => mapZhuti(item, detail.accountId))
                setFundAccountList([..._fundAccountList.list])

                let files = [];
                if (detail.attachments) {
                    files = JSON.parse(detail.attachments)
                }
                setFileList(files)
                setFieldsValue({
                    remark: detail.remark
                })
                setDetailLoading(false)
            }
        ).catch(() => setDetailLoading(false))
    }

    //未收款票据列表
    const getUncollectedBillsList = (Type, Id) => {
        setUncollectedBills_loading(false)
        api.getTransmitInvoiceList({clientType: search.id ? Type : search.clientType, clientId: search.id ?Id : search.clientId, limit: limit1, page: page1}).then(datatmp => {  
            // console.log(datatmp)
            setUncollectedBills(datatmp.list)
            setUncollectedBills_count(datatmp.count)
         }).catch(() => setDetailLoading(false))
    }
    const onChangeTable1 = (pagination, filters, sorter) => {
        page1 = uncollectedBills_currentPage = pagination.current
        limit1 = pagination.pageSize
        if (search.id) {
            getUncollectedBillsList(initialData.fromRelationType, initialData.fromRelationId)
        } else {
            getUncollectedBillsList()
        }
    }

    //关联的收款信息列表
    const getTransmitAccountDetailList = () => {
        api.getTransmitAccountDetailList({ id: search.id, editFlag: 0, limit: limit2, page: page2 }).then(datatmp => {
            setAccountDetailList(datatmp.list)
            setCollectedBills_count(datatmp.count)
        })
    }
    const onChangeTable2 = (pagination, filters, sorter) => {
        page2 = collectedBills_currentPage = pagination.current
        limit2 = pagination.pageSize
        getTransmitAccountDetailList()
    }

    const [checked, setChecked] = useState(0)
    const [checkedIdArr, setCheckedIdArr] = useState([])
    const rowSelection = {
        onSelect: (record, selected) => {
          if (!selected) {
            checkedIdArr.splice(checkedIdArr.indexOf(record.id), 1);
          } else {
            checkedIdArr.push(record.id)
          }
          updateSelected(checkedIdArr)
        },
    
        onSelectAll: (selected, selectedRows, changeRows) => {
          // console.log(selected, selectedRows, changeRows)
          if(selected){
            changeRows.map( item => checkedIdArr.push(item.id))
          }else{
            changeRows.map( item => checkedIdArr.splice(checkedIdArr.indexOf(item.id), 1))
          }
          updateSelected(checkedIdArr)
        },
        selectedRowKeys: checkedIdArr
    }

    //勾选的ids
    let selectedIds = ''
    const updateSelected = (checkedIdArr) => {
        setCheckedIdArr(checkedIdArr)
        setChecked(checkedIdArr.length)
        selectedIds = checkedIdArr
    }

    // 通过
    const onBtnPassShow = () => {
        setModalVisible(true)
    }

    const onPassCancel = () => {
        setModalVisible(false)
    }

    const onPassDescChange = (e) => {
        setPasDesc(e.target.value)
    }

    //通过
    const onApprove = () => {
        setDetailLoading(true)
        if (disabled) {
            disabled = false
            let timer = setTimeout(() => {
                disabled = true
                clearTimeout(timer)
            }, 1000)
        } else {
            setDetailLoading(false);
            message.error('请不要频繁点击')
            return
        }
        api.audit({
            id: detailDataId,
            remark: pasDesc.trim(),
            type: 0
        }).then(data => {
            getDetail(detailDataId) //确认通过后
        }).finally(() => {
            setDetailLoading(false)
            setModalVisible(false)
        })
    }

    // 驳回
    const onBtnTurnShow = () => {
        setTurnModalVisible(true)
        setTextDisplay(true)
    }

    const onTurnCancel = () => {
        setTurnModalVisible(false)
    }

    //驳回
    const onTurnApprove = () => {
        if (buttonAuthList.reject_msg_require) {
            if (textAreaVal.trim() === '') {
                setTextDisplay(false)
                return
            }
        }
        setDetailLoading(true)

        if (disabled) {
            disabled = false
            let timer = setTimeout(() => {
                disabled = true
                clearTimeout(timer)
            }, 1000)
        } else {
            setDetailLoading(false);
            message.error('请不要频繁点击')
            return
        }

        api.audit({
            id: detailDataId,
            remark: textAreaVal.trim(),
            type: 1
        }).then(data => {
            getDetail(detailDataId) //确认驳回后
        }).finally(() => {
            setDetailLoading(false)
            setTurnModalVisible(false)
        })
    }

    const onDescChange = (e) => {
        setTextAreaVal(e.target.value)
        setTextDisplay(true)
    }

    // 驳回样式
    const turnNode = () => {
        return <>
            确认驳回 <Icon type="close-circle" theme="twoTone" twoToneColor="#DC143C"/>
        </>
    }

    // 同意样式
    const agreeNode = () => {
        return <>
            确认通过 <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a"/>
        </>
    }

    // 转上级
    const onTurnUp = () => {
        setDetailLoading(true)
        api.audit({
            id: detailDataId,
            type: 2
        }).then(data => {
            getDetail(detailDataId)
        }).finally(() => {
            setDetailLoading(false)
        })
    }


    const deleteItem = (index) => {
        const delData = RKForms[index]
        RKForms.splice(index, 1)

        deleteDKIds(delData.moneyBackId)
        setRKForms([...RKForms])
    }

    const deleteDKIds = (id) => {
        setDKIds(DKIds.filter(item => {
            return id ? id !== item : true
        }))
    }

    const selectDaoKuan = (data) => {
        selectDaoKuanCB(data)
    }

    const showDaoKuan = (callback, canSelectId) => {
        setDaoKuanVisible(true)
        setCanSelectId(canSelectId)
        selectDaoKuanCB = callback
    }

    const MyRadio = (props) => {
        const {data} = props
        return <><Radio.Button disabled={!canEdit} value={data.id}>{data.companyName}</Radio.Button>{data.checked ?
            <span style={{marginRight: '10px'}}>余额：{data.balance}</span> : <span>&nbsp;</span>}</>
    }

    const mapZhuti = (item, id) => {
        let checked = false;
        if (item.id === id) {
            setRelationPro(item.projectRange)
            setShowRelationPro(true)
            checked = true;
            setRKZhuti(id)
        }

        return {...item, checked}
    }

    const selectZhuTi = (id) => {
        let newList = fundAccountList.map(item => mapZhuti(item, id));

        setFundAccountList(newList)
    }


    const setRenKuanInfo = (data) => {
        let total = 0;

        data.map(item => {
            total += item.SrJE * 100
        })
        setRKForms(data)
        setZKTotal(total / 100)
    }

    //退回列表页
	const onGoBack = () => {
		history.push({pathname:'/fund/moneyTransmit',state: { goBack: 1}})
	}

    return (
        <Spin spinning={detailLoading}>
                {
                    search.new && search.new == 1 && <Button onClick={onGoBack} type="primary" style={{
                        position: 'absolute',
                        right: '20px',
                        top: 0
                    }}>返回上一页</Button>
                }
                <div className="pro-detail">
                    <Descriptions title="基础详情页" layout="horizontal">
                        <DItem label="客户类型">{detailData.isSpecial === 1 ? <span style={{ color: 'red' }}>{`${clientInfo.typeName} (特殊转款)`}</span> : <span>{clientInfo.typeName}</span>}</DItem>
                        <DItem label={ search.id ? initialData.fromRelationType == 0 ? 'OAID' : 'QID' : search.clientType == 0 ? 'OAID' : 'QID'}>{clientInfo.id}</DItem>
                        <DItem label={(() => {
                            const label = search.id ? initialData.fromRelationType == 0 ? '客户名称' : '代理商名称' : search.clientType == 0 ? '客户名称' : '代理商名称';
                            return (
                                <span>{label}</span>
                            )
                        })()}>{clientInfo.name}</DItem>
                        <DItem label={ search.id ? initialData.fromRelationType == 0 ? '真实性验证号码类型' : '认证号码类型' : search.clientType == 0 ? '真实性验证号码类型' : '认证号码类型'}>{clientInfo.verifyTypeName}</DItem>
                        <DItem label={ search.id ? initialData.fromRelationType == 0 ? '真实性验证号码' : '认证号码' : search.clientType == 0 ? '真实性验证号码' : '认证号码'}>{clientInfo.verifyNumber}</DItem>
                    </Descriptions>

                    <>
                    <Descriptions title="当前审批进度" className="pro-detail-smailtitle"></Descriptions>
                    <div className="promotionProcess">
                        <Steps>
                            {
                                statusList.map((item, index) => {
                                    let status = 'wait'
                                    if (item.time != null && item.time.length > 1) {
                                        status = 'process'
                                    }
                                    return <Step status={status}
                                                    key={index}
                                                    value={item.time}
                                                    title={item.nodeName}
                                                    description={
                                                        <>
                                                            {item.persons && <div title={`${item.persons.map((item) => item)}`}>{`${item.persons.map((item) => item)}`}</div>}
                                                            <div title={item.statusName}>{item.statusName}</div>
                                                            <div title={item.time}>{item.time}</div>
                                                        </>
                                                    }
                                    />
                                })
                            }
                        </Steps>
                    </div>
                    </>
                   
                    <div className="line-box"></div>
                    
                    <Descriptions title="未收款票据" className="pro-detail-smailtitle" style={{paddingTop: '20px'}}></Descriptions>
                    <Table
                        dataSource={uncollectedBills}
                        rowKey="id"
                        className="table-page"
                        style={{marginBottom: 10}}
                        loading={uncollectedBills_loading}
                        pagination={{
                            pageSize: limit1,
                            total: uncollectedBills_count,
                            current: uncollectedBills_currentPage,
                            showQuickJumper: true,
                            showSizeChanger: true,
                            pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
                        }}
                        scroll={{x: 'max-content'}}
                        onChange={onChangeTable1}
                    >
                        <Column title="序号" dataIndex="number" />
                        <Column title="业务编号" dataIndex="invoiceNo" />
                        <Column title="客户/代理商名称" dataIndex="customerName" />
                        <Column title="客户类型" dataIndex="customerType" />
                        <Column title="主体" dataIndex="companyName" />
                        <Column title="金额" dataIndex="money" />
                        <Column title="类型" dataIndex="type" />
                        <Column title="票据号" dataIndex="invoiceNumber" />
                        <Column title="状态" dataIndex="stcodetag" />
                        <Column title="最后处理时间" dataIndex="updateTime" />
                        <Column title="申请人" dataIndex="applyName" />
                    </Table>
                    <div className="line-box"></div>

                    <Descriptions title="转出信息" className="pro-detail-smailtitle" style={{paddingTop: '20px'}} column={4}>
                        <dItem label="">
                            <div className="mainNameBodySel">
                                <img className="mainNameBodySelLogo" src={detailData.fromLogo} alt="" />
                                {detailData.fromAccountName}
                                <Icon className="check" type="check" />
                            </div>
                        </dItem>
                        <dItem label="可用余额">
                            <>
                            {detailData.fromAccountBalance}
                            </>
                        </dItem>
                        <dItem label="客户类型">
                            {detailData.fromCustomerInfo && detailData.fromCustomerInfo.typeName}
                        </dItem>
                        {detailData.isSpecial === 1 && <dItem label="转出人">{detailData.fromStaffName}</dItem>}
                    </Descriptions>
                    <div className="line-box"></div>

                    <Descriptions title="转入信息" className="pro-detail-smailtitle" style={{paddingTop: '20px'}} column={4}>
                        <dItem label="">
                            <div className="mainNameBodySel">
                                <img className="mainNameBodySelLogo" src={detailData.toLogo} alt="" />
                                {detailData.toAccountName}
                                <Icon className="check" type="check" />
                            </div>
                        </dItem>
                        <dItem label={(() => {
                            const label = detailData.toRelationType == 0 ? '客户名称' : '代理商名称';
                            return (
                                <span>{label}</span>
                            )
                        })()}>
                            <>
                                {detailData.toRelationName} 
                                （ {(() => {
                                    const label = detailData.toRelationType == 0 ? 'OAID' : 'ID';
                                    return (
                                        <span>{label}</span>
                                    )
                                })()}：{detailData.toRelationId}）
                            </>
                        </dItem>
                        <dItem label="客户类型">
                            <>
                            {detailData.toCustomerInfo && detailData.toCustomerInfo.typeName}
                            </>
                        </dItem>
                        <dItem label="转入人">
                            <>
                            {detailData.toStaffName}
                            </>
                        </dItem>
                        {detailData.withInvoice == 0 && <dItem label="带发票额度">否</dItem>}
                    </Descriptions>
                    <div className="line-box"></div>


                    <Descriptions title="关联的收款信息" className="pro-detail-smailtitle" style={{paddingTop: '20px'}}></Descriptions>
                    <Table
                        dataSource={accountDetailList}
                        rowKey="id"
                        pagination={{
                            pageSize: limit2,
                            total: collectedBills_count,
                            current: collectedBills_currentPage,
                            showQuickJumper: true,
                            showSizeChanger: true,
                            pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
                        }}
                        scroll={{x: 1700}}
                        onChange={onChangeTable2}
                    >
                        <Column title="序号" dataIndex="id" width={100} />
                        <Column title="OAID/QID" dataIndex="relationId" />
                        <Column title="客户类型" dataIndex="relationTypeCn" />
                        <Column title="客户/代理商名称" dataIndex="relationName" />
                        <Column title="业务编号"
                            dataIndex="businessNo"
                            render={(name, record) => {
                                if (record.businessType == "认款") {
                                    return <Link target='_blank' to={`/fund/moneyClaim/detail?id=${record.businessId}&time=${+new Date()}&sign=${CryptoJS.MD5(record.businessId+'ad629fddf8b8756d2e72e96ae035a5a4'+(+new Date())).toString()}`}>{name}</Link>
                                } else if (record.businessType == "转款（转入）") {
                                    return <Link target='_blank' to={`/fund/moneyTransmit/details?id=${record.businessId}&clientId=${record.relationId}&clientType=${record.relationType}&time=${+new Date()}&sign=${CryptoJS.MD5(record.businessId+'ad629fddf8b8756d2e72e96ae035a5a4'+(+new Date())).toString()}`}>{name}</Link>
                                } else {
                                    return <>{name}</>
                                }
                            }}
                        />
                        <Column title="业务类型" dataIndex="businessTypeCn" />
                        <Column title="主体" dataIndex="companyName" />
                        <Column title="收入" dataIndex="income" />
                        <Column title="可退转额度" dataIndex="remainMoney" />
                        <Column title="发票额度支出" dataIndex="cost" />
                        <Column title="发票余额" dataIndex="invoice" />
                        <Column title="备注" dataIndex="remark" />
                        <Column title="申请人" dataIndex="operateStaffName" />
                        <Column title="最后处理时间" dataIndex="updateTime" />
                    </Table>
                    <div className="line-box"></div>

                    <div style={{ marginTop: 16, marginBottom: 16, display: 'flex', alignItems: 'center' }}>
                        <div style={{ marginRight: 60, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <span className="title-TextSty">转款金额</span>
                            <span>{detailData.money}</span>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <span className="title-TextSty">转出发票额度</span>
                            <span>{detailData.invoice}</span>
                        </div>
                    </div>

                    <div className="line-box"></div>

                    <Descriptions title="附件上传" className="pro-detail-smailtitle"></Descriptions>
                    <Form.Item label="">
                        <div className="dragger-box" ref={draggerRef}>
                            <div className="content">
                                {
                                    fileList.length ? fileList.map((item, index) => {
                                        return <div key={index}><Icon style={{color: '#aaa'}} type='paper-clip'/><a
                                            href={item.url} target="_blank"
                                            rel="noopener noreferrer">{item.name}</a></div>
                                    }) : '无'
                                }
                            </div>
                        </div>
                    </Form.Item>
                    <div className="line-box"></div>

                    <Descriptions title="备注" style={{paddingTop: '20px'}}>
                        <DItem>{detailData.remark == '' ? '无' : detailData.remark}</DItem>
                    </Descriptions>
                    <div className="line-box"></div>

                    <Descriptions title="审批记录" style={{paddingTop: '20px', marginBottom: '0'}}></Descriptions>
                    <Table
                        dataSource={logConList}
                        rowKey="id"
                        pagination={false}
                    >
                        <Table.Column title="申请人/审批人" dataIndex="userInfo.staffName"/>
                        <Table.Column title="审批时间" dataIndex="addTime"/>
                        <Table.Column title="状态" dataIndex="status"/>
                        <Table.Column title="备注" dataIndex="chargeMsg"/>
                    </Table>

                    <Descriptions style={{marginTop:30,marginBottom:0}} title="" className="pro-detail-smailtitle">
                        <DItem label="当前状态">
                            <Tag color="#2db7f5">{detailData.stCodeName}</Tag>
                        </DItem>
                    </Descriptions>
                
                    {detailData.busCode == 3 ? '' :
                        <div className="btn-setting">
                            <Button hidden={!buttonAuthList.return_up} onClick={onTurnUp}>转上级</Button>
                            <Button hidden={!buttonAuthList.hasChargerPower} onClick={onBtnTurnShow}>驳回</Button>
                            <Modal
                                title={turnNode()}
                                // title="确认驳回"
                                visible={turnModalVisible}
                                onOk={onTurnApprove}
                                onCancel={onTurnCancel}
                            >
                                <div>
                                    <span>审批意见:</span>
                                    <Input.TextArea placeholder="请输入驳回审批意见" value={textAreaVal} onChange={onDescChange} maxLength={100} />
                                </div>
                                <span className="poine-text" hidden={textDisplay}>请填写审批意见</span>
                            </Modal>
                            <Button hidden={!buttonAuthList.hasChargerPower} type="primary" onClick={onBtnPassShow}>通过</Button>
                            <Modal
                                // title="确认通过"
                                title={agreeNode()}
                                visible={passModalVisible}
                                onOk={onApprove}
                                onCancel={onPassCancel}
                            >
                                <div>
                                <span>审批意见:</span>
                                <Input.TextArea placeholder="请输入通过审批意见" value={pasDesc} onChange={onPassDescChange} maxLength={100} />
                                </div>
                            </Modal>
                        </div>
                    }

                    
                </div>
        </Spin>

    )
}

export default Form.create()(Detail);