/**
 * 模块名称: 认款管理
 * @author wangchanghong@372163.com
 */

import React, { useState, useEffect, useRef } from 'react'
import api from '@/api'
import {
  Table,
  Form,
  Alert,
  Button,
  Input,
  Select,
  message,
  Icon,
  Row,
  Col,
  Modal,
  Switch,
  DatePicker,
  Popconfirm,
  Drawer,
  Tooltip
} from 'antd'

import { parseSearch } from '@/utils'
import CorpFilter from '@/components/CorpFilter'
import Export from '@/components/Export'
import moment from 'moment'
import { Link } from "react-router-dom"
import CryptoJS from 'crypto-js'

const { Column } = Table
const FormItem = Form.Item
const Option = Select.Option
const { RangePicker } = DatePicker
let pageSize = 10
let currentPage = 1
let shouldMe = null
let apiOperateTimer = true
let allFilter = {}

let filter = {
  companyId: '',
  relationId: '',
  oldRelationId: '',
  relationType: '',
  isDelay: 0
}

let exporfilter = {

}

let selectedIds = ''
let params = {}

const dateFormat = 'YYYY-MM-DD'

const Refund = (props) => {
  const { match, location, history } = props
  const { getFieldDecorator, validateFields, resetFields, setFieldsValue, getFieldsValue } = props.form

  const productsRef = useRef()

  const [loading, setLoading] = useState(false)
  const [list, setList] = useState([])
  const [count, setCount] = useState(0)
  const [authList, setAuthList] = useState([])
  const [expand, setExpand] = useState(false)
  const [showExport, setShowExport] = useState(false)
  const [moneyExport, setMoneyExport] = useState(false)
  const search = parseSearch(location.search)
  const [statusVal, setStatusVal] = useState(undefined)
  const [filterContent, setFilterContent] = useState([])
  const [filterInfoValue, setFilterInfoValue] = useState('')
  const [visible, setVisible] = useState(false)
  const [checked, setChecked] = useState(0)
  const [checkedIdArr, setCheckedIdArr] = useState([])
  const [payList, setPayList] = useState([])
  const [typeList, setTypeList] = useState([])
  const [faPiaoYuEListVisible, setFaPiaoYuEListVisible] = useState(false)
  const [faPiaoYuEList, setFaPiaoYuEList] = useState([])
  const [statusValName, setStatusValName] = useState(undefined)
  // const [flag, setFlag] = useState(false)
  const [shouldMeDis, setShouldMeDis] = useState(true)//风控，防止用户频繁点击switch
  const [recognitionList, setRecognitionList] = useState([])

  useEffect(() => {
    // console.log(history.location.state)
    if (!history.location.state) {
      currentPage = search.page ? +search.page : 1
      pageSize = search.pageSize ? +search.pageSize : 10
      filter.dateStart = ''
      filter.dateEnd = ''
      filter.no = ''
      filter.account = ''
      filter.money = ''
      filter.payWay = ''
      filter.relationId = ''
      filter.oldRelationId = ''
      filter.relationName = ''
      filter.relationType = ''
      filter.claimType = ''
      filter.stCode = ''
      filter.stfName = ''
      filter.relBranchId = ''
      filter.invoiceBalance = ''
      filter.isDelay = 0
    } else {
      currentPage = search.page ? +search.page : currentPage
      pageSize = search.pageSize ? +search.pageSize : pageSize
      // console.log(filter)
      setTimeout(() => {
        setFieldsValue({
          account: filter.account ? filter.account : undefined,
          money: filter.money ? filter.money : undefined,
          payWay: filter.payWay ? filter.payWay : undefined,
          relationId: filter.relationId ? filter.relationId : undefined,
          oldRelationId: filter.oldRelationId ? filter.oldRelationId : undefined,
          relationName: filter.relationName ? filter.relationName : undefined,
          relationType: filter.relationType || filter.relationType == 0 ? filter.relationType : undefined,
          claimType: filter.claimType || filter.claimType == 0 ? filter.claimType : undefined,
          stCode: filter.stCode ? filter.stCode : undefined,
          no: filter.no ? filter.no : undefined,
          stfName: filter.stfName ? filter.stfName : undefined,
          relBranchId: filter.relBranchId ? filter.relBranchId : undefined,
          invoiceBalance: filter.invoiceBalance ? filter.invoiceBalance : undefined,
          isDelay: filter.isDelay ? filter.isDelay : 0,
          date: filter.dateStart ? [moment(filter.dateStart, dateFormat), moment(filter.dateEnd, dateFormat)] : undefined
        })
      }, 1000)
    }
    shouldMe = null
    apiOperateTimer = true
    Promise.all([
      api.getPageAuth(),
      api.fundMoneyBackGetPayType(),
      api.getCommonWxTab({ dataType: 2, pageType: 1 }),
    ])
      .then(list => {
        setAuthList(list[0])
        setPayList(list[1])
        setTypeList(list[2].list)
      })
    getList()
  }, [])

  const getList = () => {
    if (apiOperateTimer) {
      setLoading(true)
      if (shouldMe) {
        filter.shouldMe = shouldMe
      } else {
        for (let i in filter) {
          if (i == 'shouldMe') delete filter[i]
        }
      }
      //filter.relationId = search.relationId
      //filter.relationType = search.relationType
      //console.log('filter:' , filter)
      api.fundMoneyClaimList({ ...filter, limit: pageSize, page: currentPage })
        .then(data => {
          setLoading(false)
          setList(data.list)
          setCount(data.count)
        })
        .catch(() => setLoading(false))
      apiOperateTimer = false
      setShouldMeDis(false)
      let timer = setTimeout(() => {
        apiOperateTimer = true
        setShouldMeDis(true)
        clearTimeout(timer)
      }, 1000)
    } else {
      return message.info('请不要频繁操作')
    }
  }
  const onSetShouldMe = e => {
    if (e == true) {
      shouldMe = 1
    } else {
      shouldMe = null
    }
    getList()
  }

  const showFaPiaoYuEMingXi = (id) => {
    api.fundMoneyClaimGetInvoiceDetailByDetailId({ claimId: id })
      .then((res) => {
        setFaPiaoYuEListVisible(true)
        setFaPiaoYuEList(res)
      })
  }

  // 查询
  const onSearch = (e) => {
    e.preventDefault()
    validateFields((err, vals) => {
      if (!err) {
        currentPage = 1
        history.replace(match.path)
        // console.log('vals=>', vals)
        // console.log('filter=>', filter)
        if (vals.isDelay) {
          vals.isDelay = 1
        } else {
          vals.isDelay = 0
        }
        if (vals.date) {
          delete vals.date
        }
        filter = { ...filter, ...vals }
        // console.log('filter2=>', filter)
        getList()
      }
    })
  }

  const onChangeTable = (pagination, filters, sorter) => {
    // console.log(pagination)
    currentPage = pagination.current
    pageSize = pagination.pageSize
    history.replace(`${match.path}?page=${currentPage}&limit=${pageSize}`)
    getList()
  }

  const onChangeCorp = (data) => {
    setChecked(0)
    setCheckedIdArr([])
    filter.companyId = data.map(item => item.id).join(',')
    history.replace(match.path)
    currentPage = 1
    getList()
  }

  const onToggle = () => {
    setExpand(!expand)
  }

  const onReset = () => {
    resetFields()
    shouldMe = null
    filter.dateStart = ''
    filter.dateEnd = ''
    filter.no = ''
    filter.account = ''
    filter.money = ''
    filter.payWay = ''
    filter.relationId = ''
    filter.oldRelationId = ''
    filter.relationName = ''
    filter.relationType = ''
    filter.claimType = ''
    filter.stCode = ''
    filter.stfName = ''
    filter.relBranchId = ''
    filter.invoiceBalance = ''
    filter.isDelay = 0
    setStatusVal(undefined)
    setStatusValName(undefined)
    history.replace(match.path)
    currentPage = 1
    getList()
  }

  // 弹出框确定
  const HandleOk = () => {
    api.saveSearchStorage({
      name: filterInfoValue,
      key: location.pathname,
      content: params
    }).then(data => {
      setVisible(false)
      message.success('添加成功')
    })
  }

  const HandleCancel = () => {
    setVisible(false) //弹出框内取消
  }

  const FilterInfoChange = (e) => {
    setFilterInfoValue(e.target.value) //弹框input值
  }

  const onChangeGetFilter = (val) => {
    // val ? setFlag(true) : setFlag(false)
    // 下拉框搜索内容
    api.getSearchStorage({ key: location.pathname }).then(data => {
      setFilterContent(data.list)
    })
  }

  const onFilterChecked = (value, val2) => {
    setStatusVal(value)
    setStatusValName(val2.props.children.props.children[0])
    // let data = JSON.parse(value)
    let data = filterContent[value].content
    let fields = getFieldsValue()
    let params = {}
    delete fields['date']

    for (let i in fields) {
      params[i] = data[i] || data[i] == 0 ? data[i] : undefined
    }
    params['date'] = data.dateStart ? [moment(data.dateStart, dateFormat), moment(data.dateEnd, dateFormat)] : undefined

    setFieldsValue(params)

    if (data.dateStart) {
      filter.dateStart = data.dateStart
      filter.dateEnd = data.dateEnd
    } else {
      filter.dateStart = ''
      filter.dateEnd = ''
    }
  }

  const onSaveFilterTerm = () => {
    validateFields((err, vals) => {
      if (!err) {
        const values = Object.values(vals).map(item => item == undefined ? '' : item)
        if (values.some(item => item !== '')) {
          params = {}
          if (vals.date && vals.date.length) {
            params.dateStart = moment(vals.date[0]).format(dateFormat)
            params.dateEnd = moment(vals.date[1]).format(dateFormat)
          }

          for (let k in vals) {//eslint-disable-line
            if (vals[k] !== undefined) {
              params[k] = vals[k]
            }
          }

          setVisible(true)
        } else {
          message.error('当前没有设置筛选条件')
        }
      }
    })
  }

  const onDelete = (id) => {
    api.fundMoneyClaimDelete({
      id: id
    }).then(res => {
      message.success('删除成功')

      const index = checkedIdArr.indexOf(id);
      if (index > -1) {
        checkedIdArr.splice(index, 1);
        updateSelected(checkedIdArr)
      }
      getList()
    })
  }

  //导出
  const onExportFile = () => {
    setShowExport(true)
    if (filter.companyId && filter.companyId.length > 0) {
      if (!Array.isArray(filter.companyId)) {
        filter.companyId = filter.companyId.split(',')
      }
    } else {
      filter.companyId = []
    }
    Object.assign(allFilter, filter, exporfilter)
  }

  // 财务导出
  const onMoneyExportFile = () => {
    setMoneyExport(true)
    Object.assign(allFilter, filter, exporfilter)
  }

  const onChangePicker = (data) => {
    if (data.length === 0) {
      filter.dateStart = ''
      filter.dateEnd = ''
    } else {
      filter.dateStart = moment(data[0]).format(dateFormat)
      filter.dateEnd = moment(data[1]).format(dateFormat)
    }
  }

  const rowSelection = {
    onSelect: (record, selected) => {
      if (!selected) {
        checkedIdArr.splice(checkedIdArr.indexOf(record.id), 1);
      } else {
        checkedIdArr.push(record.id)
      }
      updateSelected(checkedIdArr)
    },

    onSelectAll: (selected, selectedRows, changeRows) => {
      // console.log(selected, selectedRows, changeRows)
      if (selected) {
        changeRows.map(item => checkedIdArr.push(item.id))
      } else {
        changeRows.map(item => checkedIdArr.splice(checkedIdArr.indexOf(item.id), 1))
      }
      updateSelected(checkedIdArr)
    },
    selectedRowKeys: checkedIdArr
  }

  const updateSelected = (checkedIdArr) => {
    setCheckedIdArr(checkedIdArr)
    setChecked(checkedIdArr.length)
    selectedIds = checkedIdArr
  }

  const onChangeSwitch = (checked) => {
    if (checked) {
      filter.isDelay = 1
    } else {
      filter.isDelay = 0
    }
    currentPage = 1
    history.replace(match.path)
    getList()
  }

  // 筛选删除
  const onSearchDelete = (id) => {
    api.searchStorageDelete({
      id: id
    }).then(() => {
      api.getSearchStorage({ key: location.pathname }).then(data => {
        setFilterContent(data.list)
      })
    })
  }

  //认款主体
  const getSelectRecognition = () => {
    api.getCorpFilterList({ limit: global.paramsLimit }).then(data => {
      setRecognitionList(data)
    })
  }

  const relationTypeList = [
    {
      id: 0,
      name: '直销',
    },
    {
      id: 1,
      name: '代理商',
    }
  ]

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 11 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 13 },
    }
  }

  return (
    <div className="table-public">
      <CorpFilter onChange={onChangeCorp} />
      <Form onSubmit={onSearch} {...formItemLayout} className="ant-advanced-search-formTwo">
        <div className="search-item-wrap">
          <div className="search-item">
            <Row gutter={24}>
              <Col span={8}>
                <FormItem label="认款编号" labelCol={{ span: 8 }}>
                  {getFieldDecorator('no', { initialValue: '' })(<Input placeholder="请输入" />)}
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem label="客户名称/代理商名称" labelCol={{ span: 11 }}>
                  {getFieldDecorator('relationName', { initialValue: '' })(<Input placeholder="请输入" />)}
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem label="XOAID/XQID" labelCol={{ span: 8 }}>
                  {getFieldDecorator('relationId', { initialValue: '' })(<Input placeholder="请输入" />)}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={24} className={expand ? '' : 'hide'}>
              <Col span={8}>
                <FormItem label="老ID" labelCol={{ span: 8 }}>
                  {getFieldDecorator('oldRelationId', { initialValue: '' })(<Input placeholder="请输入" />)}
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem label="申请人" labelCol={{ span: 8 }}>
                  {getFieldDecorator('stfName', { initialValue: '' })(<Input placeholder="请输入" />)}
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem label="认款总额" labelCol={{ span: 8 }}>
                  {getFieldDecorator('money', { initialValue: '' })(<Input placeholder="请输入" />)}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={24} className={expand ? '' : 'hide'}>
              <Col span={8}>
                <FormItem label="认款状态" labelCol={{ span: 8 }}>
                  {getFieldDecorator('stCode')(
                    <Select placeholder="请选择">
                      {typeList.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                    </Select>
                  )}
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem label="账号/卡号/支票号/收据号" labelCol={{ span: 11 }}>
                  {getFieldDecorator('account', {
                    initialValue: '',
                    rules: [
                      //{whitespace: true, message: '不能输入空格'},
                      { pattern: /^[^ ]+$/, message: '不能输入空格' }
                    ]
                  })(<Input placeholder="请输入" />)}
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem label="客户类型" labelCol={{ span: 8 }}>
                  {getFieldDecorator('relationType')(
                    <Select placeholder="请选择">
                      {relationTypeList.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                    </Select>
                  )}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={24} className={expand ? '' : 'hide'}>
              <Col span={8}>
                <FormItem label="类型" labelCol={{ span: 8 }}>
                  {getFieldDecorator('claimType')(
                    <Select placeholder="请选择">
                      {[{ id: 0, name: '直销' }, { id: 1, name: '代理商' }, { id: 3, name: '直销渠道' }, { id: 4, name: '渠道直销' }].map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                    </Select>
                  )}
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem label="到款方式" labelCol={{ span: 8 }}>
                  {getFieldDecorator('payWay')(
                    <Select placeholder="请选择" mode="multiple">
                      {payList.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                    </Select>
                  )}
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem label="最后处理时间" labelCol={{ span: 8 }}>
                  {getFieldDecorator('date')(
                    <RangePicker onChange={onChangePicker} />
                  )}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={24} className={expand ? '' : 'hide'}>
              <Col span={8}>
                <FormItem label="认款主体" labelCol={{ span: 8 }}>
                  {getFieldDecorator('relBranchId')(
                    <Select 
                      placeholder="请选择"
                      onDropdownVisibleChange={bool => bool && getSelectRecognition()}
                      showSearch={true}
                      optionFilterProp="children"
                    >
                      {recognitionList.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                    </Select>)}
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem label="发票余额" labelCol={{ span: 8 }}>
                  {getFieldDecorator('invoiceBalance')(
                    <Select 
                      placeholder="请选择"
                      showSearch={true}
                      optionFilterProp="children"
                    >
                      <Option value={1}>大于0</Option>
                      <Option value={3}>等于0</Option>
                      <Option value={2}>小于0</Option>
                    </Select>)}
                </FormItem>
              </Col>
            </Row>
          </div>
          <span className={`expand-btn${expand ? ' show-all' : ''}`} onClick={onToggle} style={{ cursor: 'pointer', fontSize: '12px', color: '#1890ff' }}>
            <span>{expand ? '收起' : '展开'} <Icon type={expand ? 'up' : 'down'} /></span>
          </span>
        </div>
        <div className="search-btns" style={{ paddingBottom: 10 }}>

          <div className={`search-btns ${expand ? '' : 'hide'}`}>
            <h4 className="filter-title">已保存筛选方案：</h4>
            <div className="filter-select">
              <Select
                style={{ display: 'inline-block', width: '160px' }}
                placeholder="请选择"
                value={statusVal}
                onDropdownVisibleChange={bool => bool && onChangeGetFilter(bool)}
                onSelect={onFilterChecked}
                dropdownMatchSelectWidth={false}
              >
                {
                  filterContent.map((item, index) =>
                    <Option value={index} key={index}>
                      <div className="closeStyBtn">
                        {item.name}
                        {statusValName !== item.name ? <span className="closeSpan"><Icon style={{ fontSize: '10px', color: '#1890ff' }} type="close" onClick={e => {
                          e.stopPropagation()
                          onSearchDelete(item.id)
                        }} /></span> : null}
                      </div>
                    </Option>)
                }
              </Select>
            </div>
            <Button className="filter-savebtn" onClick={onSaveFilterTerm} style={{ width: 130, margin: '0 15px' }}>保存筛选条件</Button>
          </div>
          <Switch 
            checkedChildren="待审批" 
            unCheckedChildren="待审批" 
            disabled={!shouldMeDis} 
            onClick={onSetShouldMe} 
            className="shouldme-switch-btn" 
            checked={shouldMe ? true : false} 
          />
          <Button onClick={onReset}>重置</Button>
          <Button style={{ marginLeft: 8 }} type="primary" htmlType="submit">查询</Button>
          <Modal
            title="保存筛选条件"
            visible={visible}
            onOk={HandleOk}
            onCancel={HandleCancel}
          >
            <Input placeholder="请输入筛选名称" value={filterInfoValue} onChange={FilterInfoChange} />
          </Modal>
        </div>
      </Form>
      <div className="line"></div>
      <div style={{ height: '56px', display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginTop: 4 }}>
        {
          (() => {
            if (!authList.includes('has-delay-date')) {
              return
            }
            return (
              <FormItem label="已延期" labelCol={{ span: 12 }} wrapperCol={{ span: 2 }} style={{ marginBottom: 0 }}>
                {getFieldDecorator('isDelay',
                  { valuePropName: 'checked' })(
                    <Switch onChange={onChangeSwitch} />
                  )}
              </FormItem>
            )
          })()
        }
        {
          (() => {
            if (!authList.includes('export')) {
              return
            }
            return (<Button type="primary" icon="upload" onClick={onExportFile} style={{ marginLeft: 40 }}>导出</Button>)
          })()
        }
        {
          (() => {
            if (!authList.includes('finance-export')) {
              return
            }
            return (<Button type="primary" icon="upload" onClick={onMoneyExportFile} style={{ marginLeft: 15 }}>财务导出</Button>)
          })()
        }
      </div>
      <Alert message={
        <>
          <span>已选择 <span style={{ color: '#168FFF' }}>{checked}</span> 项</span>
          <span style={{ marginLeft: 20 }}>总共{count}条数据</span>
        </>
      } type="info" showIcon />
      <Table
        size="small"
        dataSource={list}
        rowKey="id"
        className="table-page"
        loading={loading}
        pagination={{
          pageSize,
          total: count,
          current: currentPage,
          showQuickJumper: true,
          showSizeChanger: true,
          pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
        }}
        // scroll={{x: 'max-content'}}
        scroll={{ x: 2200 }}
        onChange={onChangeTable}
        rowSelection={rowSelection}
      >
        {/* <Column title="序号" fixed="left" render={
          (text,record,index) => {
            return (currentPage - 1)*pageSize + index + 1
          }
        } /> */}
        <Column title="序号" fixed="left" dataIndex="id" width={80} />
        <Column title="认款编号" dataIndex="no" fixed="left" width={150} />
        <Column title="客户名称/代理商名称"
          fixed="left"
          width={220}
          ellipsis={true}
          render={(record) => {
            return (
              <Link className="operate-icon" to={`${match.path}/detail?id=${record.id}&new=1&time=${+new Date()}&sign=${CryptoJS.MD5(record.id+'ad629fddf8b8756d2e72e96ae035a5a4'+(+new Date())).toString()}`}>{record.relationName}</Link>
            )
          }}
        />
        <Column title="XOAID/XQID" dataIndex="relationId" />
        <Column title="老ID" dataIndex="oldRelationId" />
        <Column title="客户类型" dataIndex="relationTypeName" />
        <Column title="类型" key="claimType" render={(text, record) => {
          if (record.claimType == 0) return '直销'
          if (record.claimType == 1) return '代理商'
          if (record.claimType == 3) return '直销渠道'
          if (record.claimType == 4) return '渠道直销'
        }} />
        <Column title="认款主体" dataIndex="fundAccountInfo.companyName" />
        <Column title="认款总额" dataIndex="money" />
        <Column title="申请人" dataIndex="stfName" />
        <Column title="负责人" dataIndex="managerName" />
        <Column title="认款状态" dataIndex="stCodeName" />
        <Column title="申请时间" dataIndex="addTime" />
        <Column title="最后处理时间" dataIndex="date" />
        <Column title="打款方与客户关系" dataIndex="relation" />
        <Column title="第三方关系" dataIndex="authorizationInfo.relation" />
        <Column title="指定付款协议" dataIndex="authorizationInfo.isUpPayAgreementCn" />
        <Column title="委托书编号" dataIndex="authorizationInfo.no" />
        <Column title="发票余额"
          render={(record) => {
            return (
              <a className="operate-icon" onClick={() => showFaPiaoYuEMingXi(record.id)}>
                {
                  record.busCode != 3 ? record.invoiceInfo : ''
                }
              </a>
            )
          }}
        />
        <Column title="操作"
          fixed="right"
          key="set"
          width={120}
          render={(record) => {
            let del = null
            let detailNew = null
            if (record.busCode === 3) {
              del = (
                <>
                  <Popconfirm title="请确认是否删除，删除后不可恢复。" cancelText="取消" okText="确认" onConfirm={(e) => onDelete(record.id)}>
                    <a className="operate-icon" type="delete" >删除</a>
                  </Popconfirm>
                </>
              )
            }
            detailNew = (
              <>
                <Tooltip title="在新页面打开详情页" placement="bottom">
                  <Link target='_blank' className="operate-icon" to={`${match.path}/detail?id=${record.id}&time=${+new Date()}&sign=${CryptoJS.MD5(record.id+'ad629fddf8b8756d2e72e96ae035a5a4'+(+new Date())).toString()}`}>新页面</Link>
                </Tooltip>
              </>
            )
            return (
              <>
                {del}
                {detailNew}
              </>
            )
          }
          }
        />
      </Table>
      <Drawer
        title="发票余额明细"
        width={1000}
        onClose={() => setFaPiaoYuEListVisible(false)}
        visible={faPiaoYuEListVisible}
      >
        <Table
          dataSource={faPiaoYuEList}
          rowKey="id"
          className="table-page"
          scroll={{ x: 1200 }}
        >
          <Column title="序号" dataIndex="id" />
          <Column title="业务编号" dataIndex="businessNo" />
          <Column title="业务类型" dataIndex="type" />
          <Column title="本次扣减" render={(record) => {
            return (
              record.changeMoney > 0 ? '' : record.changeMoney
            )
          }} />
          <Column title="本次增加" render={(record) => {
            return (
              record.changeMoney > 0 ? record.changeMoney : ''
            )
          }} />
          <Column title="发票余额" dataIndex="remainBalance" />
          <Column title="关联主体" dataIndex="companyName" />
          <Column title="业务状态" dataIndex="auditStatus" />
          <Column title="申请人" dataIndex="stfName" />
          <Column title="最后处理时间" dataIndex="date" />
        </Table>
      </Drawer>
      <Export
        show={showExport}
        onCancel={() => {
          setShowExport(false)
          productsRef.current.changeVal()
        }} 
        tplUrl="getFundMoneyClaimExportTpl"
        fieldsUrl="getFundMoneyClaimTplItems"
        saveUrl="saveFundMoneyClaimExportTpls"
        exportUrl="fundMoneyClaimExport"
        method="export"
        parame={{ ExportType: 'fundmoneyclaim.export', id: selectedIds, ...allFilter }}
        type={1}
        cRef={productsRef}
      />
      {/* 财务导出 */}
      <Export
        show={moneyExport}
        onCancel={() => setMoneyExport(false)}
        tplUrl="getFundMoneyClaimExportTpl"
        fieldsUrl="getFundMoneyClaimTplItems"
        saveUrl="saveFundMoneyClaimExportTpls"
        exportUrl="fundMoneyClaimExport"
        method="export"
        ExportType="fundmoneyclaim_caiwu.export"
        parame={{ ExportType: 'fundmoneyclaim_caiwu.export', id: selectedIds, ...allFilter }}
      />
    </div>
  )
}

export default Form.create()(Refund)