/**
 * 模块名称: 转款管理子路由配置
 */

import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Main from './Main'
import Details from './Details'
import Add from './Add'
import SpecialAdd from './SpecialAdd'
import './assets/style.scss'

const Transmit = ({ match, history }) => {

  return (
    <div>
      <Switch> 
        <Route path={match.path + '/details'} component={Details} />
        <Route path={match.path + '/edit'} component={Add} />
        <Route path={match.path + '/add'} component={Add} />
        <Route path={match.path + '/specialAdd'} component={SpecialAdd} />
        <Route component={Main} />
      </Switch>
    </div>
  )
}

export default Transmit